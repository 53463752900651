import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  ActionsButtonIcon,
  Dropdown,
  DropdownItem,
  DropdownLink,
  MiroculusIcon,
  Tabs,
  TeamIcon,
  UsersIcon
} from '@miroculus/nucleo'
import { useParams } from 'react-router-dom'
import {
  getCurrentOrganization,
  getLoadingOrganizations
} from 'reduxModules/organizations/selectors'
import {
  deleteOrganizationMember,
  loadOrganizationMembers,
  updateOrganization
} from 'reduxModules/organizations'
import {
  updateLoading,
  removeDeviceFromOrganization
} from 'reduxModules/devices'
import {
  ORG_SETTINGS_INSTRUMENTS_COLS,
  ORG_SETTINGS_TEAMS_COLS,
  ORG_SETTINGS_USERS_COLS
} from 'cons'
import {
  newOrganizationMemberUrl,
  editOrganizationMemberUrl,
  newOrganizationTeamUrl,
  editOrganizationTeamUrl,
  deleteOrganizationTeamUrl
} from 'cons/routes'
import { LoadingWithText, useBreadcrumb, useConfirm } from 'components'
import socketClient from 'socketClient'
import classNames from 'classnames/bind'
import { userPlaceholder } from 'images'
import OrganizationInfo from './OrganizationInfo'
import ItemsTab from './ItemsTab'
import styles from './OrganizationSettings.scss'

const cx = classNames.bind(styles)

const lowerIncludes = (value, searchTerm) =>
  value.toLowerCase().includes(searchTerm.toLowerCase())

const filterTeams = (searchTerm, teams) =>
  teams.filter(({ title }) => lowerIncludes(title, searchTerm))

const filterMembers = (searchTerm, members) =>
  members.filter(({ name }) => lowerIncludes(name, searchTerm))

const filterInstruments = (searchTerm, instruments) =>
  instruments.filter(
    ({ id, givenName }) => lowerIncludes(givenName ?? id, searchTerm)
  )

const InstrumentRows = ({ data: instrumentsData, onDeleteDevice }) => {
  const { confirm } = useConfirm()

  let rowKey = 'organization-settings-instrument-row'
  if (!instrumentsData?.length) {
    return (
      <tr key={rowKey} data-testid={rowKey}>
        <td colSpan={3} className={cx('no-results')}>No instruments found</td>
      </tr>
    )
  }

  return instrumentsData.map(({ id, givenName, status }, ix) => {
    rowKey = `organization-settings-instrument-row-${ix}`

    const handleDelete = () => confirm(
      `You're about to remove "${givenName ?? id}" from your organization, are you sure?`,
      () => onDeleteDevice(id)
    )

    return (
      <tr key={rowKey} data-testid={rowKey}>
        <td className={cx('instrument-name')}>{givenName ?? id}</td>
        <td className={cx('instrument-status')}>{status}</td>
        <td className={cx('actions')}>
          <Dropdown align='full' button={<ActionsButtonIcon />}>
            <DropdownItem onSelect={handleDelete}>
              Remove
            </DropdownItem>
          </Dropdown>
        </td>
      </tr>
    )
  })
}

const UsersRows = ({ data: userData, orgSlug, userEmail, onDeleteMember }) => {
  const { confirm } = useConfirm()

  let rowKey = 'organization-settings-user-row'
  if (!userData?.length) {
    return (
      <tr key={rowKey} data-testid={rowKey}>
        <td colSpan={3} className={cx('no-results')}>No users found</td>
      </tr>
    )
  }

  return userData.map(({ id, name, role, email }, ix) => {
    rowKey = `organization-settings-user-row-${ix}`

    const handleDelete = () => confirm(
      `You're about to remove ${name} from your organization, are you sure?`,
      () => onDeleteMember(id)
    )

    return (
      <tr key={rowKey} data-testid={rowKey}>
        <td className={cx('user-name')}>
          <div className={cx('user-avatar-image')}>
            <img src={userPlaceholder} alt='Avatar' />
          </div>
          <div>
            <div>
              {name}
            </div>
            <div className={cx('user-email')}>
              {email}
            </div>
          </div>
        </td>
        <td className={cx('user-role')}>{role}</td>
        <td className={cx('actions')}>
          <Dropdown align='full' button={<ActionsButtonIcon />}>
            <DropdownLink to={editOrganizationMemberUrl(orgSlug, id)}>
              Edit info
            </DropdownLink>
            {(email !== userEmail) && (
              <DropdownItem onSelect={handleDelete}>
                Remove
              </DropdownItem>
            )}
          </Dropdown>
        </td>
      </tr>
    )
  })
}

const TeamsRows = ({ data: teamsData, orgSlug }) => {
  let rowKey = 'organization-settings-team-row'
  if (!teamsData?.length) {
    return (
      <tr key={rowKey} data-testid={rowKey}>
        <td colSpan={3} className={cx('no-results')}>No teams found</td>
      </tr>
    )
  }

  return teamsData.map(({ id, title: name, $memberCount: memberCount }, ix) => {
    rowKey = `organization-settings-team-row-${ix}`

    return (
      <tr className='organization-team-row' key={rowKey} data-testid={rowKey}>
        <td data-testid={`team-row-${id}`} className={cx('team-name')}>
          {name}
        </td>
        <td className={cx('team-members')}>
          {memberCount > 1 ? `${memberCount} Members` : `${memberCount} Member`}
        </td>
        <td className={cx('actions')}>
          <Dropdown align='full' button={<ActionsButtonIcon />}>
            <DropdownLink
              data-testid={`edit-team-${id}-button`}
              to={editOrganizationTeamUrl(orgSlug, id)}
            >
              Edit team info
            </DropdownLink>
            <DropdownLink
              data-testid={`edit-team-${id}-button`}
              to={deleteOrganizationTeamUrl(orgSlug, id)}
            >
              Delete team
            </DropdownLink>
          </Dropdown>
        </td>
      </tr>
    )
  })
}

const QuickInfo = (Icon, text) => ({ count = 0 }) => (
  <div className={cx('quick-info')}>
    <Icon />
    <span>
      {count} {text}{count > 1 ? 's' : ''}
    </span>
  </div>
)

const InstrumentsQuickInfo = QuickInfo(MiroculusIcon, 'Instrument')
const UsersQuickInfo = QuickInfo(UsersIcon, 'Member')
const TeamsQuickInfo = QuickInfo(TeamIcon, 'Team')

const TABS_TITLES = ['Info', 'Instruments', 'Users', 'Teams']

const getInstrumentsTabData = (items, { onDeleteDevice }) => ({
  title: TABS_TITLES[1],
  quickInfo: <InstrumentsQuickInfo count={items?.length} />,
  currentTabList: (
    <InstrumentRows
      data={items}
      onDeleteDevice={onDeleteDevice}
    />
  ),
  columnNames: ORG_SETTINGS_INSTRUMENTS_COLS,
  withActions: true
})

const getUsersTabData = (items, { orgSlug, onDeleteMember, userEmail }) => ({
  title: TABS_TITLES[2],
  button: {
    text: '+ New User',
    link: newOrganizationMemberUrl(orgSlug)
  },
  quickInfo: <UsersQuickInfo count={items?.length} />,
  currentTabList: (
    <UsersRows
      data={items}
      orgSlug={orgSlug}
      userEmail={userEmail}
      onDeleteMember={onDeleteMember}
    />
  ),
  columnNames: ORG_SETTINGS_USERS_COLS,
  withActions: true
})

const getTeamsTabData = (items, { orgSlug }) => ({
  title: TABS_TITLES[3],
  button: {
    text: '+ New Team',
    link: newOrganizationTeamUrl(orgSlug)
  },
  quickInfo: <TeamsQuickInfo count={items?.length} />,
  currentTabList: <TeamsRows data={items} orgSlug={orgSlug} />,
  columnNames: ORG_SETTINGS_TEAMS_COLS,
  withActions: true
})

const FilteredInstrumentsTab = ItemsTab(getInstrumentsTabData, filterInstruments)
const FilteredUsersTab = ItemsTab(getUsersTabData, filterMembers)
const FilteredTeamsTab = ItemsTab(getTeamsTabData, filterTeams)

// Dummy component to prevent rendering a tab when is not visible
const DummyTab = () => false

export const OrganizationSettings = ({
  loading,
  loadOrganization,
  organizationName,
  instruments = [],
  users = [],
  teams = [],
  initialTab = 0,
  userEmail,
  subscribeToDevices,
  unsubscribeFromDevices,
  onUpdateOrganization,
  onDeleteDevice,
  onDeleteMember
}) => {
  // Current Tab index (O: Info, 1: Instruments, 2: Users, 3: Members)
  const [currentTab, setCurrentTab] = useState(initialTab)
  const { orgSlug } = useParams()

  useEffect(() => {
    loadOrganization?.(orgSlug)
    subscribeToDevices(orgSlug)
    return () => {
      unsubscribeFromDevices(orgSlug)
    }
  }, [orgSlug])

  useBreadcrumb(() => [
    { text: organizationName, href: `/organization/${orgSlug}` },
    { text: 'Organization Settings' },
    { text: TABS_TITLES[currentTab] }
  ], [organizationName, currentTab])

  const itemsTabs = [
    [FilteredInstrumentsTab, instruments],
    [FilteredUsersTab, users],
    [FilteredTeamsTab, teams]
  ]

  const renderTab = ([Tab, items], index) => currentTab - 1 === index
    ? <Tab
        key={index}
        loading={loading}
        items={items}
        orgSlug={orgSlug}
        userEmail={userEmail}
        onDeleteMember={onDeleteMember}
        onDeleteDevice={onDeleteDevice}
      />
    : <DummyTab key={index} />

  const handleUpdateOrganization = values =>
    onUpdateOrganization(orgSlug, values)

  return (
    <div className={cx('container')}>
      {loading && <LoadingWithText message='Loading organization settings...' />}
      <h1 className={cx('header-title')}>Organization Settings</h1>
      <Tabs
        titles={TABS_TITLES}
        defaultValue={currentTab}
        onChange={setCurrentTab}
      >
        <OrganizationInfo
          slug={orgSlug}
          name={organizationName}
          onUpdateOrganization={handleUpdateOrganization}
        />
        {itemsTabs.map(renderTab)}
      </Tabs>
    </div>
  )
}

OrganizationSettings.propTypes = {
  loading: PropTypes.bool.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    $memberCount: PropTypes.number.isRequired
  })),
  instruments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    givenName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  })),
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  })),
  organizationName: PropTypes.string,
  initialTab: PropTypes.number
}

const mapStateToProps = (state) => {
  const currentOrganization = getCurrentOrganization(state)
  const isLoading = getLoadingOrganizations(state)

  return {
    instruments: currentOrganization.devices,
    users: currentOrganization.members,
    teams: currentOrganization.teams,
    userEmail: state.auth.user.email,
    loading: isLoading,
    organizationName: currentOrganization?.name
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadOrganization: async (organizationSlug) => {
    await dispatch(loadOrganizationMembers(organizationSlug))
  },
  onUpdateOrganization: (...args) => dispatch(updateOrganization(...args)),
  onDeleteMember: (id) => dispatch(deleteOrganizationMember(id)),
  onDeleteDevice: (id) => dispatch(removeDeviceFromOrganization(id)),
  subscribeToDevices: async (orgSlug) => {
    try {
      await dispatch(updateLoading(true))
      await socketClient.subscribeToDevices(orgSlug)
    } finally {
      await dispatch(updateLoading(false))
    }
  },
  unsubscribeFromDevices: async (orgSlug) => {
    await socketClient.unsubscribeFromDevices(orgSlug)
  }
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(OrganizationSettings)
