import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { Button } from '@miroculus/nucleo'
import styles from './ErrorModalContent.scss'

const cx = classnames.bind(styles)

const ErrorModalContent = ({ errorCount, onCancel, onConfirm }) => (
  <div className={cx('container')}>
    <div className={cx('titleContainer')}>
      <h1 className={cx('title')}>{errorCount} errors</h1>
    </div>
    <div className={cx('mainContainer')}>
      <div className={cx('main')}>
        <p className={cx('explanation')}>
          You have {errorCount > 1 ? 'multiple errors ' : 'an error '}
          in your protocol.
          <br />
          You can save, but you cannot finish the protocol until you revise
          {errorCount > 1 ? ' these errors' : ' this error'}.
        </p>
        <h1 className={cx('question')}>
          Would you like to review the protocol?
        </h1>
        <div className={cx('buttonsContainer')}>
          <Button flavor='secondary' onClick={onCancel}>
            Ignore
          </Button>
          <Button flavor='secondary' onClick={onConfirm}>
            Yes
          </Button>
        </div>
      </div>
    </div>
  </div>
)

ErrorModalContent.propTypes = {
  errorCount: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}

export default ErrorModalContent
