import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import InstrumentDashboard from './InstrumentDashboard'
import socketClient from 'socketClient'
import { selectDevice, updateLoading } from 'reduxModules/devices'
import { devicesSelector } from 'reduxModules/devices/selectors'
import { getOrganizations, findOrganizationBySlug }
  from 'reduxModules/organizations/selectors'

function Devices () {
  const { orgSlug } = useParams()
  const devices = useSelector(devicesSelector)
  const { connecting, loading } = useSelector(state => state.devices)
  const organizations = useSelector(getOrganizations)

  const dispatch = useDispatch()

  useEffect(() => {
    const subscribeToDevices = async () => {
      try {
        await dispatch(updateLoading(true))
        await socketClient.subscribeToDevices(orgSlug)
      } finally {
        await dispatch(updateLoading(false))
      }
    }

    const unsubscribeFromDevices = async () => {
      await socketClient.unsubscribeFromDevices(orgSlug)
    }

    subscribeToDevices()
    return () => {
      unsubscribeFromDevices()
    }
  }, [orgSlug])

  const organization = findOrganizationBySlug(organizations, orgSlug)
  const { canAccessAnaconda } = organization?.$permissions ?? {}

  const handleInstrumentClick = (id) => {
    if (canAccessAnaconda) {
      dispatch(selectDevice(id))
    }
  }

  return (
    <InstrumentDashboard
      instruments={devices}
      connecting={connecting}
      loading={loading}
      onInstrumentClick={handleInstrumentClick}
      organizationName={organization?.name}
      organizationSlug={organization?.slug}
      canAccessAnaconda={canAccessAnaconda}
    />
  )
}

export default Devices
