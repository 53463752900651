import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@miroculus/nucleo'
import classnames from 'classnames/bind'
import styles from './HeadingWithButton.scss'

const cx = classnames.bind(styles)

const HeadingWithButton = ({
  title,
  titleAnnex,
  buttonTextElement,
  onButtonClick,
  buttonLinkTo,
  titleAnnexAlignment = 'left',
  showSmallTitle = false,
  buttonTestId = 'header-with-filters-button'
}) => {
  const withButton = (onButtonClick || buttonLinkTo) && buttonTextElement

  return (
    <div className={cx('headingWithButtonContainer', { withButton })}>
      {showSmallTitle ? <h2>{title}</h2> : <h1>{title}</h1>}
      {titleAnnex && titleAnnexAlignment !== 'right' ? titleAnnex : null}
      {withButton && (
        <div className={cx('headingButton')}>
          {titleAnnex && titleAnnexAlignment === 'right' ? titleAnnex : null}
          <Button
            size='small'
            data-testid={buttonTestId}
            onClick={onButtonClick}
            to={buttonLinkTo}
          >
            {buttonTextElement}
          </Button>
        </div>
      )}
    </div>
  )
}

HeadingWithButton.propTypes = {
  onButtonClick: PropTypes.func,
  buttonLinkTo: PropTypes.string,
  buttonTextElement: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showSmallTitle: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleAnnex: PropTypes.node,
  titleAnnexAlignment: PropTypes.string
}

export default HeadingWithButton
