import React, { useCallback } from 'react'
import { Button, Modal } from '@miroculus/nucleo'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames/bind'
import browserHistory from 'browserHistory'
import { useParams } from 'react-router-dom'
import { organizationSettingsUrl } from 'cons/routes'
import { LoadingWithText } from 'components'
import { getDeletingTeam, teamsSelector } from 'reduxModules/organizations/selectors'
import styles from './DeleteTeamModal.scss'
import { deleteOrganizationTeam } from 'reduxModules/organizations'

const cx = classNames.bind(styles)

const DeleteTeamModal = () => {
  const dispatch = useDispatch()
  const { orgSlug, id } = useParams()
  const teamId = parseInt(id, 10)

  const organizationTeam = useSelector((state) =>
    teamsSelector(state).find((team) => team.id === teamId) ?? {}
  )

  const handleClose = useCallback(() => {
    browserHistory.push(organizationSettingsUrl(orgSlug))
  }, [])

  const deletingOrganization = useSelector(getDeletingTeam)

  const handleConfirmDelete = useCallback(async () => {
    await dispatch(deleteOrganizationTeam({ teamId, organization: orgSlug }))
    browserHistory.push(organizationSettingsUrl(orgSlug))
  })

  if (deletingOrganization) {
    return (
      <LoadingWithText message='Deleting team...' />
    )
  }

  return (
    <Modal label='DeleteTeamModal' onClose={handleClose}>
      <div className={cx('container')}>
        <h2>
          {organizationTeam.title || 'Delete Team'}
        </h2>
        <p>
          Are you sure you want to delete this team?
          By deleting this team, you will lose all the data associated with it.
        </p>
        <div className={cx('buttons-container')}>
          <Button size='small' onClick={handleClose} flavor='secondary'>
            Cancel
          </Button>
          <Button size='small' onClick={handleConfirmDelete}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteTeamModal
