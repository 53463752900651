import history from 'browserHistory'
import * as Sentry from '@sentry/browser'
import apiClient from '@miroculus/api-client'
import { toast } from 'react-toastify'
import { instrumentDetailsUrl } from '../../constants/routes'

const UPDATE_RUNS = 'anaconda-web/devices/UPDATE_RUNS'
const UPDATE_LOADING = 'anaconda-web/runs/UPDATE_LOADING'
const UPDATE_SAVING = 'anaconda-web/runs/UPDATE_SAVING'
const UPDATE_SELECTED_RUN_ID = 'anaconda-web/runs/UPDATE_SELECTED_RUN_ID'

export const updateRuns = (runs) => ({
  type: UPDATE_RUNS,
  payload: { runs }
})

export const updateSelectedRunId = (selectedRunId) => ({
  type: UPDATE_SELECTED_RUN_ID,
  payload: { selectedRunId }
})

export const updateLoading = (loading) => ({
  type: UPDATE_LOADING,
  payload: { loading }
})

export const updateSaving = (saving) => ({
  type: UPDATE_SAVING,
  payload: { saving }
})

/**
 * Receives an array of runs with "reagentExpirationDate" as timestamp and return
 * the runs with "reagentExpirationDate" as Date
 * @param {Array<Object>} runs
 * @returns Array<Object>
 */
const castReagentExpirationDate = (runs) => runs.map(r => (
  r.reagentExpirationDate
    ? ({
        ...r,
        reagentExpirationDate: new Date(r.reagentExpirationDate)
      })
    : r
))

// Bound action creators
export const loadDeviceRuns = (deviceId) => async (dispatch) => {
  dispatch(updateLoading(true))
  try {
    const { body } = await apiClient.get('/protocol-runs', { device: deviceId })

    dispatch(updateRuns(
      castReagentExpirationDate(body)
    ))
  } catch (e) {
    Sentry.captureException(e)
    toast.error('There was a problem loading the device runs history.')
  }
  dispatch(updateLoading(false))
}

export const updateRunInformation = ({ values, deviceId, orgSlug }) => async (dispatch, getState) => {
  const { runs: { selectedRunId, runs } } = getState()

  dispatch(updateSaving(true))
  try {
    await apiClient.put(`/protocol-runs/${selectedRunId}`, { ...values, reagentExpirationDate: values?.reagentExpirationDate?.getTime() })

    const runIndex = runs.findIndex((r) => r.id === selectedRunId)
    dispatch(updateRuns([
      ...runs.slice(0, runIndex),
      {
        ...runs[runIndex],
        ...values
      },
      ...runs.slice(runIndex + 1)
    ]))

    history.push(instrumentDetailsUrl(orgSlug, deviceId))
    toast.success('The run information was successfully updated!')
  } catch (e) {
    Sentry.captureException(e)
    toast.error('There was an error submitting the run information')
  }
  dispatch(updateSaving(false))
}

export const loadRunInformation = (deviceId, runId) => async (dispatch, getState) => {
  const { runs: { runs } } = getState()
  // this in order to prevent an unnecessary request, however there are some edge cases
  // not covered by this check, e.g. a device with no runs
  if (!runs.length) {
    await dispatch(loadDeviceRuns(deviceId))
  }

  dispatch(updateSelectedRunId(Number(runId)))
}

const initialState = {
  runs: [],
  loading: false,
  saving: false,
  selectedRunId: null
}

// Reducer
export default function reducer (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case UPDATE_RUNS:
    case UPDATE_LOADING:
    case UPDATE_SAVING:
    case UPDATE_SELECTED_RUN_ID:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
