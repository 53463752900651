import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CodeMirror from '../CodeMirror/CodeMirror'
import styles from './CodeEditor.scss'
import classnames from 'classnames/bind'

import { JSHINT } from 'jshint'
window.JSHINT = JSHINT

/* eslint-disable no-multi-spaces */
const jshintOptions = {
  asi: true,      // to forgive semicolons
  esversion: 9    // for ES9 (const, let, {...}, etc)
}
/* eslint-enable no-multi-spaces */

const cx = classnames.bind(styles)

const DEFAULT_OPTIONS = {
  autoRefresh: true,
  mode: 'javascript',
  lineNumbers: true,
  lint: jshintOptions,
  foldGutter: true,
  gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
  styleActiveLine: true,
  styleSelectedText: true
}

export const THEMES = {
  light: 'base16-light',
  dark: 'base16-dark'
}

export const LINE_STATUS = {
  LINE_RUNNING: 1,
  LINE_DONE: 2,
  LINE_ERROR: 3
}

export const EDITOR_STATUS = {
  EDITOR_RUNNING: 1,
  EDITOR_DONE: 2,
  EDITOR_STOP: 3,
  EDITOR_PAUSED: 4,
  EDITOR_ERROR: 5
}

export default class CodeEditor extends Component {
  static propTypes = {
    onScriptChange: PropTypes.func.isRequired,
    onThemeToggle: PropTypes.func.isRequired,
    onScriptRun: PropTypes.func.isRequired,
    onScriptStop: PropTypes.func.isRequired,
    script: PropTypes.string,
    theme: PropTypes.oneOf([THEMES.light, THEMES.dark]),
    line: PropTypes.shape({
      number: PropTypes.number,
      status: PropTypes.number
    }),
    status: PropTypes.number,
    readOnly: PropTypes.bool
  }

  static defaultProps = {
    script: '',
    theme: THEMES.light,
    line: {
      number: -1,
      status: 0
    },
    status: 0,
    readOnly: false
  }

  componentDidMount () {
    if (this.props.script) {
      this.forceUpdate()
    }
  }

  checkLineStatus = () => {
    const { LINE_RUNNING, LINE_ERROR, LINE_DONE } = LINE_STATUS
    const { EDITOR_PAUSED, EDITOR_STOP, EDITOR_DONE } = EDITOR_STATUS

    const { line, status } = this.props

    if (this.marked || (line.status === LINE_DONE && this.marked)) this.marked.clear()

    if (this.editor && this.editor.codeMirror && status !== EDITOR_DONE) {
      if (line.status === 1) {
        this.editor.codeMirror.scrollIntoView(line.number - 1)
      }

      this.marked = this.editor.codeMirror.doc.markText({
        line: line.number - 1, ch: 0
      }, {
        line: line.number, ch: 0
      }, {
        className: cx({
          styledBackground: line.status === LINE_RUNNING,
          pausedBackground: status === EDITOR_PAUSED,
          errorBackground: line.status === LINE_ERROR || status === EDITOR_STOP
        })
      })
    }
  }

  handleKeyDown = (evt) => {
    const {
      onThemeToggle,
      onScriptRun,
      onScriptStop,
      script
    } = this.props

    if (evt.ctrlKey) {
      const keyCodes = {
        e: 69,
        i: 73,
        p: 80,
        s: 83
      }

      switch (evt.keyCode) {
        case keyCodes.e:
          onScriptRun(script)
          evt.preventDefault()
          break
        case keyCodes.i:
          onThemeToggle()
          evt.preventDefault()
          break
        case keyCodes.s:
          onScriptStop()
          break
      }
    }
  }

  render () {
    const {
      onScriptChange,
      script,
      theme,
      readOnly,
      hintItems
    } = this.props

    const options = {
      ...DEFAULT_OPTIONS,
      theme,
      readOnly
    }

    this.checkLineStatus()

    return (
      <div className={styles.container} onKeyDown={this.handleKeyDown}>
        <CodeMirror
          id='editor'
          ref={(component) => {
            this.editor = component
          }}
          value={script}
          defaultValue={script}
          options={options}
          onChange={onScriptChange}
          hintItems={hintItems}
        />
      </div>
    )
  }
}
