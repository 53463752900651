// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrganizationInfo_org-info__3xsKp{width:60%;max-width:50rem;min-width:16rem;display:flex;flex-direction:column}.OrganizationInfo_org-info__3xsKp button{margin-top:3rem;align-self:flex-end}", ""]);
// Exports
exports.locals = {
	"org-info": "OrganizationInfo_org-info__3xsKp"
};
module.exports = exports;
