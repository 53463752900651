import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import { Button, DraftsIcon, Input, Modal, Select, TextArea } from '@miroculus/nucleo'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import classnames from 'classnames/bind'
import { saveProtocol } from 'reduxModules/protocols'
import { getOrganizationsAndTeamsList } from 'reduxModules/organizations/selectors'
import { toast } from 'react-toastify'
import { copyProtocolUrl, editProtocolUrl } from 'cons/routes'
import { FormField } from 'components'
import { PROTOCOL_VISIBILITY_OPTIONS } from 'cons'
import styles from './ProtocolCreation.scss'
import { saveProtocolUrl } from '../../constants/routes'

// Protocol operations
const CREATING = 0
const EDITING = 1
const COPYING = 2

function getProtocolOperation (isCopyUrl, id) {
  if (isCopyUrl) return COPYING
  return id ? EDITING : CREATING
}

// Modal titles indexed by protocol operation
const MODAL_TITLES = [
  'Create protocol',
  'Edit protocol',
  'Copy protocol'
]

const cx = classnames.bind(styles)

const ProtocolCreationForm = ({
  handleSubmit, errors, status, protocolOperation, handleClose,
  getFieldProps, setFieldValue, locationOptions, types
}) => {
  const nameProps = getFieldProps('name')
  const typeProps = getFieldProps('type')
  const visibilityProps = getFieldProps('visibility')
  const locationProps = getFieldProps('teamId')
  const descriptionProps = getFieldProps('description')

  // we cannot use `getFieldProps().onChange` here
  // because these ones are custom components
  const handleTypeChange = value => setFieldValue('type', value)
  const handleVisibilityChange = value => setFieldValue('visibility', value)
  const handleLocationChange = value => setFieldValue('teamId', value)

  const canSave = !errors.name && !errors.type && !errors.visibility

  return (
    <form className={cx('form')} onSubmit={handleSubmit}>
      <FormField title='Protocol Name'>
        <div className={cx('field')}>
          {(errors.name || (status?.name)) &&
            <p className={cx('errorMessage')}>{errors.name || status.name}</p>}
          <Input
            {...nameProps}
            placeholder='Enter a name'
            autoFocus
          />
        </div>
      </FormField>
      {protocolOperation !== COPYING &&
        <FormField title='Type'>
          <Select
            {...typeProps}
            options={types}
            placeholder='Select type...'
            onChange={handleTypeChange}
          />
        </FormField>}
      <FormField title='Visibility'>
        <Select
          {...visibilityProps}
          options={PROTOCOL_VISIBILITY_OPTIONS}
          placeholder='Select visibility...'
          onChange={handleVisibilityChange}
        />
      </FormField>
      {protocolOperation !== EDITING &&
        <div
          className={cx('locationContainer')}
          data-testid='protocol-creation-modal-team'
        >
          <FormField title='Location'>
            <Select
              {...locationProps}
              options={locationOptions}
              onChange={handleLocationChange}
              contained
            />
          </FormField>
        </div>}
      <FormField title='Protocol Description'>
        <TextArea
          {...descriptionProps}
          placeholder='Enter a description (optional).'
          maxLength={640}
        />
      </FormField>
      <section className={cx('buttons')}>
        <Button
          flavor='secondary'
          size='small'
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          size='small'
          disabled={!canSave}
        >
          Save
        </Button>
      </section>
    </form>
  )
}

const buildTeamOption = ({ name, id }) => {
  return { label: name, value: id, disabled: false }
}

const buildOrganizationOption = ({ name }, organizationColorIndex) => {
  const optionLabel =
    <div className={cx(`colorBubble${organizationColorIndex % 3}`)}>{name}</div>

  return { label: optionLabel, value: name, disabled: true }
}

const buildDraftsOption = (personalTeam) => ({
  label: <div className={cx('optionWithIcon')}><DraftsIcon />Drafts</div>,
  value: personalTeam.id
})

const Schema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter the protocol name'),
  type: Yup.number()
    .required('Please select the protocol type'),
  description: Yup.string(),
  visibility: Yup.string().required('Please select the protocol visibility'),
  teamId: Yup.string()
})

export const ProtocolCreation = ({
  dispatch,
  id,
  name = '',
  description = '',
  type,
  types,
  visibility,
  team,
  organizations,
  personalTeam
}) => {
  const isCopyUrl = useRouteMatch({
    path: [copyProtocolUrl(':teamId', ':id')],
    exact: false
  })
  const isNewUrl = useRouteMatch({
    path: [saveProtocolUrl(':teamId', 'new')],
    exact: false
  })

  const history = useHistory()
  const { teamId: teamIdParam, id: protocolId } = useParams()

  // The teamId is defined in the URL when copying a protocol
  const teamId = isCopyUrl || isNewUrl ? Number(teamIdParam) : team

  const locationOptions = organizations.reduce((opts, org) => {
    // Skip if the team is not in the current organization
    if (!org.teams.find(t => t.id === teamId)) return opts

    return [
      ...opts,
      buildOrganizationOption({ name: org.name }, 0),
      ...org.teams.map(buildTeamOption)
    ]
  }, personalTeam ? [buildDraftsOption(personalTeam)] : [])

  const {
    errors,
    status,
    handleSubmit,
    getFieldProps,
    setFieldValue
  } = useFormik({
    initialValues: {
      name,
      type,
      description,
      visibility,
      teamId
    },
    onSubmit: async (values, actions) => {
      actions.setStatus(undefined)

      try {
        await dispatch(saveProtocol({
          ...values,
          protocolCopyFromId: protocolId !== 'new' ? protocolId : null,
          project: 1
        }))
      } catch (e) {
        // We assume `uniqueness` error in the API means that the name already exists
        // We should update this once the API gets better at error reporting
        if (e.message.includes('uniqueness')) {
          actions.setStatus({
            name: 'Name already exists'
          })
        } else {
          toast.error(e.message)
        }
      }
    },
    validationSchema: Schema
  })

  const handleClose = () => {
    if (isCopyUrl) {
      history.goBack()
    } else {
      history.push(editProtocolUrl(teamId, id))
    }
  }

  const protocolOperation = getProtocolOperation(isCopyUrl, id)

  const label = protocolOperation === COPYING
    ? 'CopyProtocol'
    : 'CreateProtocol'

  const title = MODAL_TITLES[protocolOperation]

  return (
    <Modal label={label} onClose={handleClose}>
      <h1>{title}</h1>
      <ProtocolCreationForm
        status={status}
        errors={errors}
        getFieldProps={getFieldProps}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        locationOptions={locationOptions}
        protocolOperation={protocolOperation}
        handleClose={handleClose}
        types={types}
      />
    </Modal>
  )
}

const SelectPropType = PropTypes.shape({
  value: PropTypes.number,
  label: PropTypes.string
})

const idAndNameShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
})

ProtocolCreation.propTypes = {
  name: PropTypes.string,
  type: PropTypes.number,
  description: PropTypes.string,
  team: PropTypes.number,
  types: PropTypes.arrayOf(SelectPropType).isRequired,
  id: PropTypes.any,
  organizations: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    teams: PropTypes.arrayOf(idAndNameShape).isRequired
  })).isRequired,
  personalTeam: idAndNameShape
}

const mapStateToProps = (state) => {
  const {
    protocols: {
      currentProtocol: {
        name,
        description,
        type,
        visibility,
        cartridge,
        team,
        id
      },
      cartridges,
      protocolTypes
    }
  } = state

  const allOrganizations = getOrganizationsAndTeamsList(state)

  let personalTeam
  const organizations = allOrganizations?.filter(currOrganization => {
    const personalTeamFound = currOrganization?.teams?.find(
      ({ name: teamName }) => teamName === 'Personal Workspace'
    )

    if (personalTeamFound) {
      personalTeam = personalTeamFound
      return false
    }
    return true
  })

  return {
    name,
    description,
    type: type ?? '',
    types: protocolTypes,
    cartridge,
    cartridges,
    visibility: visibility ?? '',
    id,
    team,
    personalTeam,
    organizations
  }
}

export default connect(mapStateToProps)(ProtocolCreation)
