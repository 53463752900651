import React, { useState } from 'react'
import classNames from 'classnames/bind'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Button, Input } from '@miroculus/nucleo'
import { FormField } from 'components'
import { getFieldError, canSubmit } from 'utils/forms'
import styles from './OrganizationInfo.scss'

const cx = classNames.bind(styles)

const OrganizationInfoSchema = Yup.object().shape({
  name: Yup.string().required('The organization name is required')
})

const OrganizationInfo = ({
  slug,
  name,
  onUpdateOrganization
}) => {
  const [submitting, setSubmitting] = useState(false)

  const formik = useFormik({
    initialValues: {
      name
    },
    onSubmit: async (values, actions) => {
      actions.setStatus(undefined)
      setSubmitting(true)

      try {
        await onUpdateOrganization(values)
        toast.success('Your organization info has been updated.')
      } catch (e) {
        toast.error('There was an error updating your organization info.')
        throw e
      } finally {
        setSubmitting(false)
      }
    },
    validationSchema: OrganizationInfoSchema
  })

  return (
    <form className={cx('org-info')} onSubmit={formik.handleSubmit}>
      <FormField title='Organization id'>
        <Input frozen value={slug} />
      </FormField>
      <FormField title='Organization name'>
        <Input
          {...formik.getFieldProps('name')}
          name='name'
          type='text'
          placeholder='Enter the organization name'
          error={getFieldError(formik, 'name')}
          autoFocus
        />
      </FormField>
      <Button disabled={submitting || !canSubmit(formik)} type='submit'>
        Save
      </Button>
    </form>
  )
}

export default OrganizationInfo
