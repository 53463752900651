import React, {
  useState,
  useEffect,
  useCallback
} from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames/bind'
import styles from './ProtocolRouting.scss'
import { Button, Checkbox } from '@miroculus/nucleo'
import { SolutionCanvas, LoadingWithText } from 'components'
import { useClientRect } from 'hooks'
import { pathsSelector } from 'reduxModules/protocols/selectors'
import browserHistory from 'browserHistory'
import { DASHBOARD_URL } from 'cons/routes'
import { updateProtocolSolution } from 'reduxModules/protocols'

const cx = classNames.bind(styles)
const ANIMATION_MIN_DELAY = 0
const ANIMATION_MAX_DELAY = 1000

const ProtocolSolution = ({
  paths,
  name,
  stepsList,
  onCloseClick
}) => {
  const [stepsChecklist, setStepsChecklist] = useState(stepsList.map(stepName => ({ name: stepName, checked: true })))
  const [animationSpeed, setAnimationSpeed] = useState(50)
  const [animationStep, setAnimationStep] = useState(-1)
  const [animating, setAnimating] = useState(false)
  const [currentStep, setCurrentStep] = useState(-1)
  const [checkAll, setCheckAll] = useState(true)
  const [canvasContainer, { width = 0, height = 0 }] = useClientRect([])

  const handleItemClick = (index) => {
    if (animating) return
    setStepsChecklist([
      ...stepsChecklist.slice(0, index),
      {
        ...stepsChecklist[index],
        checked: !stepsChecklist[index].checked
      },
      ...stepsChecklist.slice(index + 1)
    ])
  }

  const handleCheckAll = useCallback(() => {
    if (animating) return
    if (checkAll) {
      setStepsChecklist(stepsChecklist.map(s => ({ ...s, checked: false })))
    } else {
      setStepsChecklist(stepsChecklist.map(s => ({ ...s, checked: true })))
    }
    setCheckAll(!checkAll)
  })

  const checkItem = useCallback((index) => {
    const newCheckList = stepsChecklist.map((s, k) => {
      if (k === index) return ({ ...s, checked: true })
      return ({ ...s, checked: false })
    })
    setStepsChecklist(newCheckList)
  })

  const handleAnimation = useCallback(() => {
    if (animating) {
      // Stop animation
      setAnimationStep(-1)
    } else {
      // Start animation
      setAnimationStep(0)
    }
    setAnimating(!animating)
  }, [animating])

  const handleClickNext = useCallback(() => {
    checkItem(currentStep + 1)
    setCurrentStep(currentStep + 1)
  }, [currentStep])

  const handleClickPrev = useCallback(() => {
    checkItem(currentStep - 1)
    setCurrentStep(currentStep - 1)
  }, [currentStep])

  const handleChangeSpeed = useCallback((e) => {
    e.preventDefault()
    setAnimationSpeed(e.target.value)
  }, [animationSpeed])

  const checkedPaths = stepsChecklist
    .map((s, index) => ({ ...s, index }))
    .filter(s => s.checked)
    .map(s => s.index)

  let pathsToRender
  if (animationStep > -1 && animating) {
    // Render animation
    pathsToRender = paths.slice(0, animationStep)
  } else {
    // Render checked steps
    pathsToRender = paths.filter(electrode => (checkedPaths.includes(electrode[1])))
  }

  useEffect(() => {
    let interval = null
    const frameDelay = ANIMATION_MIN_DELAY + (ANIMATION_MAX_DELAY - ANIMATION_MIN_DELAY) * (1 - (animationSpeed / 100))
    if (animating) {
      interval = setTimeout(() => {
        setAnimationStep(step => step + 1)
        if (animationStep === paths.length) setAnimating(false)
      }, frameDelay)
    } else if (!animating) {
      setTimeout(interval)
    }
    return () => setTimeout(interval)
  }, [animating, animationStep])

  return (
    <div className={cx('protocolSolution')}>
      <div className={cx('header')}>
        <h1>Solution - {name}</h1>
        <div className={cx('button')}>
          <Button size='small' onClick={onCloseClick} flavor='secondary'>
            Back
          </Button>
        </div>
      </div>
      <div className={cx('body')}>
        <div className={cx('solutionCanvas')} ref={canvasContainer}>
          <SolutionCanvas
            paths={pathsToRender}
            width={width}
            height={height}
          />
        </div>
        <div className={cx('rightPanel')}>
          <div className={cx('currentStep')}>
            {checkedPaths.length === 1 ? `Current step: ${checkedPaths[0] + 1}. ${stepsChecklist[checkedPaths[0]].name}` : null}
          </div>
          <div className={cx('navButtons')}>
            <Button
              size='small'
              onClick={handleClickPrev}
              disabled={currentStep <= 0 || animating}
            >
              &lt;
            </Button>
            <Button
              size='small'
              onClick={handleAnimation}
            >
              {animating ? 'Stop' : 'Animate'}
            </Button>
            <Button
              size='small'
              onClick={handleClickNext}
              disabled={currentStep === stepsChecklist.length - 1 || animating}
            >
              &gt;
            </Button>
            <input
              type='range'
              min={0} max={100}
              value={animationSpeed}
              onChange={handleChangeSpeed}
            />
          </div>
          <div className={cx('item', 'grey')}>
            <Checkbox
              checked={checkAll}
              onChange={handleCheckAll}
              disabled={animating}
              label={checkAll ? 'Uncheck all' : 'Check all'}
            />
          </div>
          <div className={cx('stepsList')}>
            {stepsChecklist.map((step, k) => (
              <div className={cx('item')} key={k}>
                <div className={cx('stepNumber')}>
                  {k + 1}.
                </div>
                <Checkbox
                  checked={step.checked}
                  onChange={() => { handleItemClick(k) }}
                  disabled={animating}
                  label={step.name}
                />
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  )
}

export const ProtocolRouting = ({
  paths,
  stepsList,
  onCloseSolutionClick,
  protocol,
  loadingSolution,
  onUnmount
}) => {
  // Unmount
  useEffect(() => {
    return () => {
      onUnmount()
    }
  }, [])
  return (
    <div className={cx('container')}>
      {loadingSolution && <LoadingWithText message='Loading protocol routing...' />}
      {!!paths.length && (
        <ProtocolSolution
          paths={paths}
          stepsList={stepsList}
          name={protocol}
          onCloseClick={onCloseSolutionClick}
        />)}
    </div>
  )
}

const stepsListSelector = (state) => {
  if (!state.protocols.currentProtocol.solution || !state.protocols.currentProtocol.solution.steps) return []
  return state.protocols.currentProtocol.solution.steps
}

const mapStateToProps = (state) => ({
  paths: pathsSelector(state),
  stepsList: stepsListSelector(state),
  loadingSolution: state.protocols.loadingSolution,
  protocol: state.protocols.currentProtocol.name
})

const mapDispatchToProps = dispatch => ({
  onCloseSolutionClick: () => {
    browserHistory.push(DASHBOARD_URL)
  },
  onUnmount: () => {
    dispatch(updateProtocolSolution(null))
  }
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(ProtocolRouting)
