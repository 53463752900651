// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Collapsible_header__2QY53{-webkit-user-select:none;-moz-user-select:none;user-select:none;cursor:pointer}.Collapsible_content__19zzO{transition:height ease-in-out .3s}.Collapsible_content__19zzO:not(.Collapsible_show__3MFcf){overflow:hidden}", ""]);
// Exports
exports.locals = {
	"header": "Collapsible_header__2QY53",
	"content": "Collapsible_content__19zzO",
	"show": "Collapsible_show__3MFcf"
};
module.exports = exports;
