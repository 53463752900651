import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Input, Modal, TextArea, Spinner } from '@miroculus/nucleo'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import classnames from 'classnames/bind'
import { FormField, DatePickerField } from 'components'
import styles from './RunDetails.scss'
import { updateRunInformation, loadRunInformation } from 'reduxModules/runs'
import { currentRunSelector } from 'reduxModules/runs/selectors'
import { instrumentDetailsUrl } from 'cons/routes'
import { secondsToTimeString, getFormattedDate, normalizeCartridgeName } from 'utils'

const cx = classnames.bind(styles)

const StaticField = ({ label, value }) => (
  <div className={cx('staticField')}>
    <div className={cx('label')}>{label}</div>
    <div className={cx('value')}>{value}</div>
  </div>
)

// This form has no errors since field values are optional and
// there is no possibility to get errors
function RunDetailsForm ({
  cartridgeType,
  deviceId,
  experimentStatus,
  getFieldProps,
  handleClose,
  handleSubmit,
  protocolName,
  protocolVersion,
  recoveryStatus,
  startedAt,
  setFieldValue,
  totalTime,
  userName,
  submitting,
  canEdit
}) {
  const sampleIdProps = getFieldProps('sampleId')
  const batchInformationProps = getFieldProps('batchInformation')
  const cartridgeIdProps = getFieldProps('cartridgeId')
  const reagentLotProps = getFieldProps('reagentLot')
  const reagentExpirationDateProps = getFieldProps('reagentExpirationDate')
  const descriptionProps = getFieldProps('description')

  const handleReagentExpirationDateChange = value => setFieldValue('reagentExpirationDate', value)

  return (
    <form className={cx('form')} onSubmit={handleSubmit}>
      <div className={cx('staticFieldsContainer')}>
        <StaticField label='Date' value={getFormattedDate(startedAt)} />
        <StaticField label='Device ID' value={deviceId} />
        <StaticField label='Protocol Name' value={protocolName} />
        <StaticField label='Protocol Version' value={protocolVersion} />
        <StaticField label='Cartridge Type' value={cartridgeType} />
        <StaticField label='Status' value={experimentStatus} />
        <StaticField label='Recovery Status' value={recoveryStatus} />
        <StaticField label='Total Time' value={secondsToTimeString(totalTime / 1000)} />
        <StaticField label='User Name' value={userName} />
      </div>
      <FormField title='Sample ID'>
        <div className={cx('field')}>
          <Input
            placeholder='Sample ID'
            frozen={!canEdit}
            {...sampleIdProps}
          />
        </div>
      </FormField>
      <FormField title='Batch Information'>
        <div className={cx('field')}>
          <Input
            frozen={!canEdit}
            placeholder='Batch information'
            {...batchInformationProps}
          />
        </div>
      </FormField>
      <FormField title='Cartridge ID'>
        <div className={cx('field')}>
          <Input
            placeholder='Cartridge ID'
            frozen={!canEdit}
            {...cartridgeIdProps}
          />
        </div>
      </FormField>
      <FormField title='Reagent Lot'>
        <div className={cx('field')}>
          <Input
            frozen={!canEdit}
            placeholder='Reagent Lot'
            {...reagentLotProps}
          />
        </div>
      </FormField>
      <FormField title='Reagent Expiration Date'>
        <div className={cx('field')}>
          <DatePickerField
            {...reagentExpirationDateProps}
            selected={reagentExpirationDateProps?.value}
            onChange={handleReagentExpirationDateChange}
            disabled={!canEdit}
          />
        </div>
      </FormField>
      <FormField title='Description'>
        <TextArea
          {...descriptionProps}
          maxLength={640}
          frozen={!canEdit}
          placeholder='Description'
        />
      </FormField>
      <section className={cx('buttons')}>
        <Button
          flavor='secondary'
          size='small'
          onClick={handleClose}
        >
          {canEdit ? 'Cancel' : 'Go Back'}
        </Button>
        {canEdit && (
          <Button
            type='submit'
            size='small'
            disabled={submitting}
          >
            {submitting ? 'Saving...' : 'Save'}
          </Button>
        )}
      </section>
    </form>
  )
}

const Schema = Yup.object().shape({
  sampleId: Yup.string(),
  batchInformation: Yup.string(),
  cartridgeId: Yup.string(),
  reagentLot: Yup.string(),
  reagentExpirationDate: Yup.date(),
  description: Yup.string()
})

const RunDetails = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { loading, saving } = useSelector((state) => state.runs)
  const { orgSlug, deviceId, runId } = useParams()

  useEffect(() => {
    dispatch(loadRunInformation(deviceId, runId))
  }, [dispatch, runId])

  const runDetails = useSelector(currentRunSelector)
  const canEdit = runDetails?.$permissions?.canEdit

  const {
    status,
    handleSubmit,
    getFieldProps,
    setFieldValue
  } = useFormik({
    initialValues: {
      sampleId: runDetails?.sampleId,
      batchInformation: runDetails?.batchInformation,
      cartridgeId: runDetails?.cartridgeId,
      reagentLot: runDetails?.reagentLot,
      reagentExpirationDate: runDetails?.reagentExpirationDate,
      description: runDetails?.description
    },
    onSubmit: async (values) => {
      await dispatch(updateRunInformation({ values, orgSlug, deviceId }))
    },
    validationSchema: Schema,
    enableReinitialize: true
  })

  const handleClose = () => {
    history.push(instrumentDetailsUrl(orgSlug, deviceId))
  }

  if (loading) {
    return (
      <div className={cx('centered')}>
        <Spinner />
      </div>
    )
  }

  if (!runDetails) {
    return (
      <div className={cx('centered')}>
        <h2>Run not found</h2>
      </div>
    )
  }

  return (
    <Modal label='RunDetails' onClose={handleClose}>
      <h1>Run details</h1>
      <RunDetailsForm
        canEdit={canEdit}
        getFieldProps={getFieldProps}
        handleSubmit={handleSubmit}
        setFieldValue={setFieldValue}
        handleClose={handleClose}
        recoveryStatus={runDetails.recoveryStatus}
        deviceId={runDetails.device}
        status={status}
        experimentStatus={runDetails.status}
        totalTime={runDetails.totalTime}
        startedAt={runDetails.startedAt}
        userName={runDetails.user?.name}
        protocolName={runDetails.protocolName}
        protocolVersion={runDetails.protocolVersion}
        cartridgeType={normalizeCartridgeName(runDetails.electrodeLayout?.name)}
        submitting={saving}
      />
    </Modal>
  )
}

export default RunDetails
