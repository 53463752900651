import React, { useState } from 'react'
import {
  NavBar, Link, DraftsIcon, RecentIcon, ArrowDownIcon, ArrowUpIcon, Dropdown,
  DropdownLink, CogIcon
} from '@miroculus/nucleo'
import { ACCOUNT_SETTINGS_URL, DRAFTS_URL, LOGOUT_URL, RECENT_URL, organizationUrl, teamUrl } from 'cons/routes'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { connect } from 'react-redux'
import apiClient from '@miroculus/api-client'
import { useRouteMatch, matchPath, useLocation } from 'react-router-dom'
import Collapsible from '../Collapsible/Collapsible'
import {
  getOrganizations,
  getLoadingOrganizations
} from 'reduxModules/organizations/selectors'
import { getLoading } from 'reduxModules/auth/selectors'
import { userPlaceholder } from 'images'
import styles from './Sidebar.scss'

const cx = classnames.bind(styles)

const teamsPropType = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  permissions: PropTypes.shape({
    canCreateProtocol: PropTypes.bool,
    canEditProtocol: PropTypes.bool,
    canEditWorkspace: PropTypes.bool,
    canSendProtocol: PropTypes.bool
  })
}))

const UserAccountDropdownActions = () => (
  <Dropdown
    button={<ArrowDownIcon />}
    align='right'
  >
    <DropdownLink to={ACCOUNT_SETTINGS_URL}>Account Settings</DropdownLink>
    <DropdownLink to={LOGOUT_URL}>Logout</DropdownLink>
  </Dropdown>
)

UserAccountDropdownActions.propTypes = {}

const UserAccountBrief = ({
  userName,
  email
}) => (
  <div className={cx('userAccountBrief')} data-testid='userAccountBrief'>
    <div className={cx('userAccountBriefAvatar')}>
      <div className={cx('userAccountBriefAvatarImage')}>
        <img src={userPlaceholder} alt='Avatar' />
      </div>
    </div>
    <div className={cx('userAccountBriefInfo')}>
      <p data-testid='navBarUserName' className={cx('userAccountBriefInfoUserName')}>{userName}</p>
      <p data-testid='navBarUserEmail' className={cx('userAccountBriefInfoEmail')}>{email}</p>
    </div>
    <UserAccountDropdownActions />
  </div>
)

UserAccountBrief.propTypes = {
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
}

const OrganizationTeams = ({
  teams
}) => (
  <ul className={cx('organizationTeamsList')}>
    {teams.map(({ id: teamId, title }) =>
      <li className={cx({ selected: useRouteMatch(teamUrl(teamId)) })} key={teamId}>
        <Link
          to={teamUrl(teamId)}
          aria-current={useRouteMatch(teamUrl(teamId))?.isExact}
          data-testid={`team-button-${title}`}
        >
          {title}
        </Link>
      </li>
    )}
  </ul>
)

OrganizationTeams.propTypes = {
  teams: teamsPropType.isRequired
}

const Shortcuts = ({
  recentSelected = false,
  draftsSelected = false
}) => (
  <ul className={cx('shortcutsList', 'navBarBlock')}>
    <li className={cx({ selected: recentSelected })}>
      <Link to={RECENT_URL} aria-current={recentSelected}>
        <RecentIcon />Recent
      </Link>
    </li>
    <li className={cx({ selected: draftsSelected })}>
      <Link to={DRAFTS_URL} aria-current={draftsSelected}>
        <DraftsIcon />Drafts
      </Link>
    </li>
  </ul>
)

const OrganizationList = ({
  organizations,
  pathname,
  collapsedState,
  onCollapsedStateChange
}) => (
  <ul className={cx('organizationsList')}>
    {organizations?.filter(({ name }) => !!name).map(({ name, teams, slug }) => {
      const organizationName = name ?? 'No Organization'

      const match = matchPath(pathname, {
        path: organizationUrl(':orgSlug'),
        exact: true,
        strict: false
      })

      const selected = match?.params?.orgSlug === slug

      const handleCollapsibleChange = (open) => {
        onCollapsedStateChange({ ...collapsedState, [slug]: open })
      }

      return (
        <li
          className={cx('organizationsListItem', 'navBarBlock')}
          key={organizationName}
          data-testid={`organization-link-${organizationName}`}
        >
          <Link
            className={cx('organizationsListItemLink', { selected })}
            aria-current={selected}
            to={organizationUrl(slug)}
            data-testid={`sidebar-org-${slug}`}
          >
            {organizationName}
          </Link>
          <Collapsible
            data-testid={`organization-collapsible-${slug}`}
            header={teams?.length
              ? (expanded) => (expanded ? <ArrowDownIcon /> : <ArrowUpIcon />)
              : () => null}
            open={collapsedState[slug]}
            onChange={handleCollapsibleChange}
          >
            <OrganizationTeams teams={teams} />
          </Collapsible>
        </li>
      )
    })}
  </ul>
)

/**
 * Render the app Sidebar with Recent view and Drafts view links and also lists
 * User Organizations and its Teams. The header of the Sidebar contains some
 * User information and actions for logout and show the Account Settings modal
 */
export const Sidebar = ({
  open,
  organizations,
  userName,
  email,
  loadingOrganizations,
  loading
}) => {
  const recentMatch = useRouteMatch(RECENT_URL)
  const rootMatch = useRouteMatch('/')
  const draftsMatch = useRouteMatch(DRAFTS_URL)
  const { pathname } = useLocation()

  const [collapsedState, setCollapsedState] =
    useState(apiClient.storage.get('sidebar-state') || {})

  const handleCollapsedStateChange = (newState) => {
    setCollapsedState(newState)
    apiClient.storage.set('sidebar-state', newState)
  }

  const showBlankState = !loading && !loadingOrganizations && !organizations?.length

  return (
    <div className={cx('container', { open })} data-testid='sidebar'>
      <NavBar>
        <UserAccountBrief userName={userName} email={email} />
        <div className={cx('navBarActions')}>
          <Shortcuts
            recentSelected={recentMatch || rootMatch?.isExact}
            draftsSelected={draftsMatch}
          />
          <OrganizationList
            organizations={organizations}
            pathname={pathname}
            collapsedState={collapsedState}
            onCollapsedStateChange={handleCollapsedStateChange}
          />
          {showBlankState &&
            <div className={cx('organizationsListEmpty')}>
              <CogIcon />
              <p>No organization has been added yet.</p>
            </div>}
        </div>
      </NavBar>
    </div>

  )
}

Sidebar.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    teams: teamsPropType.isRequired
  })),
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  loadingOrganizations: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  userName: state.auth.user.name,
  email: state.auth.user.email,
  organizations: getOrganizations(state),
  loadingOrganizations: getLoadingOrganizations(state),
  loading: getLoading(state)
})

export default connect(
  mapStateToProps
)(Sidebar)
