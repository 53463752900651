import React from 'react'
import Joyride from 'react-joyride'

const styles = {
  options: {
    zIndex: 100000
  },
  buttonNext: {
    backgroundColor: '#013ca5'
  },
  buttonBack: {
    color: '#013ca5'
  }
}

const Walkthrough = ({ steps, onFinish, ...rest }) => (
  <Joyride
    callback={(state) => {
      const walkthroughCompleted = state.lifecycle === 'complete' &&
        state.index === (steps.length - 1) &&
        state.action === 'next'
      const walkthroughSkipped = state.action === 'skip'
      if (walkthroughCompleted || walkthroughSkipped) {
        onFinish()
      }
    }}
    steps={steps}
    styles={styles}
    showSkipButton
    continuous
    hideCloseButton
    {...rest}
  />
)

export default Walkthrough
