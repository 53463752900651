import browserHistory from 'browserHistory'
import { ANACONDA_URL, PROJECT_SELECTION_URL, instrumentsUrl } from 'cons/routes'
import socketClient from 'socketClient'
import apiClient from '@miroculus/api-client'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/browser'
import {
  loadElectrodeLayout,
  turnOnElectrodes,
  turnOffElectrodes,
  turnOnElectrode,
  turnOffElectrode,
  turnOffAllElectrodes,
  setDroplets,
  setDroplet
} from 'reduxModules/electrodeLayout'
import {
  updateScript,
  updateEditorStatus,
  EDITOR_STATUS,
  onExecutingLine,
  onLineError,
  onLineDone,
  onScriptEnds
} from 'reduxModules/codeEditor'
import { addLog } from 'reduxModules/log'
import {
  resetTempData,
  addValues,
  setTempSources,
  initRuns,
  addValue,
  setHV
} from 'reduxModules/data/actions'
import { updateExperiment } from 'reduxModules/experiment'
import { currentDeviceSelector, currentProjectSelector } from './selectors'
import {
  cleanNotifyMe,
  parseJSON
} from 'utils'
import {
  TYPE_DEVICE,
  DEVICE_STATUS_WAITING,
  DEVICE_STATUS_BUSY,
  DEVICE_STATUS_AVAILABLE,
  TYPE_SCRIPT,
  TYPE_LINE,
  TYPE_COCO_EVENT,
  TYPE_SURE_MOVE,
  TYPE_TEMP,
  TYPE_LOG,
  STATUS_DONE,
  STATUS_ERROR,
  STATUS_START,
  STATUS_STOP,
  ERROR_PAYLOAD_TOO_BIG
} from 'cons'
import { DEVICE_HV_STATUS } from 'reduxModules/data'
import { notifyMe } from 'notifications'
import { updateMoveBackbone } from '../electrodeLayout'

const UPDATE_DEVICES = 'anaconda-web/devices/UPDATE_DEVICES'
const UPDATE_DEVICE = 'anaconda-web/devices/UPDATE_DEVICE'
const ADD_DEVICE = 'anaconda-web/devices/ADD_DEVICE'
const DELETE_DEVICE = 'anaconda-web/devices/DELETE_DEVICE'
const CLEAR_DEVICES = 'anaconda-web/devices/CLEAR_DEVICES'
const UPDATE_SELECTED_DEVICE_ID = 'anaconda-web/devices/UPDATE_SELECTED_DEVICE_ID'
const DEVICES_UPDATE_CONNECTING = 'anaconda-web/devices/DEVICES_UPDATE_CONNECTING'
const DEVICES_UPDATE_LOADING = 'anaconda-web/devices/DEVICES_UPDATE_LOADING'
const UPDATE_LAST_TEMP_UPDATE = 'app/anaconda/devices/UPDATE_LAST_TEMP_UPDATE'
const UPDATE_PREVIOUS_MESSAGE_TYPE = 'app/anaconda/devices/UPDATE_PREVIOUS_MESSAGE_TYPE'

const ELECTROPAD_UPDATES_INTERVAL = 50 // 50ms means 20fps
let electropadUpdateTimeout = null

// Bound action creators
export const onMessage = (message) => (dispatch, getState) => {
  const { msg, timestamp, deviceId } = message

  const parsedMsg = parseJSON(msg)
  if (!parsedMsg) return

  const {
    type,
    status,
    line,
    error,
    response,
    payload,
    params
  } = parsedMsg

  const {
    devices,
    selectedDeviceId
  } = getState().devices

  // @TODO: remove this when the duplicate rooms problem is solved
  const selectedDeviceIndex = devices.map(device => device.id).indexOf(selectedDeviceId)
  const selectedDevice = devices[selectedDeviceIndex]

  if (!deviceId || !selectedDevice || deviceId !== selectedDevice.id) return

  switch (type) {
    case TYPE_LINE:
      dispatch(onLineMessage({ status, line, timestamp, response, error }))
      break
    case TYPE_COCO_EVENT:
      dispatch(onCocoEventMessage(response, timestamp))
      break
    case TYPE_LOG:
      dispatch(onLogMessage({ payload, timestamp }))
      break
    case TYPE_TEMP:
      dispatch(onTempMessage({ parsedMsg, timestamp }))
      break
    case TYPE_SCRIPT:
      dispatch(onScriptMessage({ status, timestamp }))
      break
    case TYPE_SURE_MOVE:
      dispatch(onSureMoveMessage(params, timestamp))
      break
  }
}

const onMultipleTempsMessage = (data) => (dispatch, getState) => {
  const heaters = Object.keys(data).reduce(
    (prev, key) => data[key].temp !== undefined
      ? ({ ...prev, [key]: data[key].temp })
      : prev,
    {}
  )

  const {
    data: {
      temp: {
        sources,
        tracking
      }
    },
    codeEditor
  } = getState()

  if (sources.length === 0) {
    const newSources = []
    Object.keys(heaters).forEach((heater, index) => {
      // Sources for temp data
      newSources.push({
        name: heater,
        color: (4 + index * 72) % 255,
        active: true,
        lastValue: 0,
        tracking: true
      })
    })

    dispatch(setTempSources(newSources))
    dispatch(initRuns())
  }

  if (data.hv && typeof data.hv.hv === 'number') {
    const { hv } = data.hv
    const isLow = hv < Number(process.env.HV_ALERT_THRESHOLD)
    if (codeEditor.status === EDITOR_STATUS.RUNNING && isLow) {
      dispatch(addLog(
        `HV Dropped Alert, value: ${hv}`,
        'notification',
        'cocoscript'
      ))
    }
    dispatch(setHV(hv > 0
      ? (isLow ? DEVICE_HV_STATUS.LOW : DEVICE_HV_STATUS.HIGH)
      : DEVICE_HV_STATUS.OFF
    ))
  }

  if (tracking) {
    dispatch(
      addValues(
        'temp',
        codeEditor.status,
        // @TODO: experiments
        '',
        heaters
      )
    )
  }
}

const onSingleTempMessage = (data) => (dispatch, getState) => {
  const {
    data: {
      temp: {
        sources,
        tracking
      }
    }
  } = getState()

  const heaterExists = sources.find(source => source.name === data.heater)

  if (!heaterExists) {
    dispatch(
      setTempSources(
        sources.concat([{
          name: data.heater,
          color: (4 + sources.length * 72) % 255,
          active: true,
          lastValue: 0,
          tracking: true
        }])
      )
    )

    dispatch(initRuns())
  }

  if (tracking) {
    dispatch(
      addValue(
        'temp',
        getState().codeEditor.status,
        // @TODO: experiments
        '',
        data.heater,
        data.temp
      )
    )
  }
}

/*
  Send updates if timeout is not clear or if the else condition match

  Else condition: if the previousMessageType is different from the current command send an update
  with the opposite action

  Timeout is not clear: This happens when client is receiving the same message type within the
  timeout interval
*/

let pendingElectrodes = []
let previousMessageType = null

const onElectropadUpdates = (response, command, timestamp = 0) => (dispatch) => {
  const currentAction = command === 'coco.electrodeOn'
    ? turnOnElectrodes
    : turnOffElectrodes
  const oppositeAction = command === 'coco.electrodeOn'
    ? turnOffElectrodes
    : turnOnElectrodes

  const sameTypeAsPrevious = command === previousMessageType

  const startTimeout = () => {
    electropadUpdateTimeout = setTimeout(() => {
      dispatch(currentAction(pendingElectrodes, timestamp))
      pendingElectrodes = []
      electropadUpdateTimeout = null
      previousMessageType = null
    }, ELECTROPAD_UPDATES_INTERVAL)
  }

  if (!previousMessageType || sameTypeAsPrevious) {
    previousMessageType = command
    pendingElectrodes.push(response[command])
    if (!electropadUpdateTimeout) startTimeout()
  } else {
    if (pendingElectrodes.length) {
      dispatch(oppositeAction(pendingElectrodes, timestamp))
    }
    pendingElectrodes = [].concat([response[command]])
    previousMessageType = command
    if (electropadUpdateTimeout) {
      clearTimeout(electropadUpdateTimeout)
      startTimeout()
    }
  }
}

const getCommandHandlers = (response) => ({
  'coco.getVacuumStatus': () => (dispatch) => { dispatch(addLog(`Vacuum status: ${response}`, 'notification', 'cocoscript')) },
  'coco.getHeaterStatus': () => (dispatch) => { dispatch(addLog(`Heater status: ${response}`, 'notification', 'cocoscript')) },
  'coco.getSyringeStatus': () => (dispatch) => { dispatch(addLog(`Syringe status: ${response}`, 'notification', 'cocoscript')) },
  'coco.getCelsius': () => (dispatch) => { dispatch(addLog(`getCelsius result: ${response}`, 'notification', 'cocoscript')) },
  'coco.getRelativeHumidity': () => (dispatch) => { dispatch(addLog(`getRelativeHumidity result: ${response}`, 'notification', 'cocoscript')) },
  'coco.getCelsiusAlt': () => (dispatch) => { dispatch(addLog(`getCelsiusAlt result: ${response}`, 'notification', 'cocoscript')) },
  'coco.getG': () => (dispatch) => { dispatch(addLog(`getG result: ${JSON.stringify(response)}`, 'notification', 'cocoscript')) },
  'coco.electrodesOn': turnOnElectrodes,
  'coco.electrodesOff': turnOffElectrodes,
  'coco.electrodeOn': turnOnElectrode,
  'coco.electrodeOff': turnOffElectrode,
  'coco.electrodeOffAll': turnOffAllElectrodes,
  'coco.cartridgeValues': setDroplets,
  'coco.hasDroplet': setDroplet
})

const onCocoEventMessage = (response, timestamp = 0, line = 0) => (dispatch) => {
  const command = Object.keys(response).shift()

  if (Object.values(response).shift() === 'notifyMe') {
    const message = String(response.params.message)
    notifyMe(message)
    dispatch(addLog(message, 'notification', 'cocoscript'))
  }

  // Support log command responses
  if (response.command === 'log') {
    // Note that the message coming from a log command
    // might be wrapped in a params object [and w/o line]
    // when issued by a DMF.js command.
    const logMessage = response.params
      ? response.params.message
      : `line ${line}: ${response.message}`

    switch (response.level) {
      case 'debug':
        dispatch(addLog(`[DEBUG] ${logMessage}`, 'out', 'cocoscript'))
        break
      case 'info':
        dispatch(addLog(`[INFO] ${logMessage}`, 'notification', 'cocoscript'))
        break
      case 'error':
        dispatch(addLog(`[ERROR] ${logMessage}`, 'error', 'cocoscript'))
        break
      case 'warn':
        dispatch(addLog(`[WARN] ${logMessage}`, 'warn', 'cocoscript'))
        break
      default:
        break
    }
  }

  const commandHandler = getCommandHandlers(response[command])[command]
  if (command && commandHandler) {
    switch (command) {
      case 'coco.hasDroplet':
        dispatch(commandHandler(response[command], response.response))
        break
      case 'coco.electrodeOn':
      case 'coco.electrodeOff':
        dispatch(onElectropadUpdates(response, command, timestamp))
        break
      case 'coco.electrodeOffAll':
        dispatch(commandHandler(timestamp))
        break
      default:
        dispatch(commandHandler(response[command], timestamp))
    }
  }
}

export const onSureMoveMessage = (params, timestamp) => (dispatch, getState) => {
  const {
    name,
    message,
    params: {
      backbone,
      stuckAt
    }
  } = params

  const {
    moveBackbone,
    moveBackboneTimestamp
  } = getState().electrodeLayout

  switch (name) {
    case 'DROPLET_WILL_START':
      // dispatch actions if current backbone is different from the previous one
      if (JSON.stringify(moveBackbone) !== JSON.stringify(backbone) && moveBackboneTimestamp < timestamp) {
        dispatch(addLog(`${name}: Using this backbone [${backbone}]`, 'notification', 'suremove'))
        dispatch(updateMoveBackbone(backbone, timestamp))
      }
      break
    case 'DROPLET_LOST':
    case 'DROPLET_INFEASIBLE':
      dispatch(addLog(`${name}: ${message}`, 'error', 'suremove'))
      break
    case 'DROPLET_STUCK':
      dispatch(addLog(`${name}: Stuck at [${stuckAt}]`, 'error', 'suremove'))
      break
    // DROPLET_SHORTCUT / DROPLET_UNSTUCK
    default:
      dispatch(addLog(`${name}: ${message}`, 'notification', 'suremove'))
      break
  }
}

export const onLineMessage = ({ status, line, timestamp, response, error }) => (dispatch, getState) => {
  const {
    codeEditor: { currentLine },
    electrodeLayout: { moveBackbone, moveBackboneTimestamp }
  } = getState()

  switch (status) {
    case STATUS_START:
      // avoid unnecessary dispatches when line is executing
      if (!line) return
      if (currentLine.number || currentLine.number !== line) {
        dispatch(onLineDone(line, timestamp))
        dispatch(onExecutingLine(line, timestamp))
      }
      break
    case STATUS_DONE:
      if (response) {
        dispatch(onCocoEventMessage(response, timestamp, line))
      }
      // Clear the moveBackbone if there is a rendered moveBackbone in electrodeLayout
      // With this we asume the line done belongs to a sureMove command
      if (moveBackbone.length && moveBackboneTimestamp < timestamp) {
        dispatch(updateMoveBackbone([], timestamp))
      }
      break
    case STATUS_ERROR:
      dispatch(onLineError(error, line || -1, timestamp))
      dispatch(addLog(`Error, ${line ? 'line ' + line : 'Script'}, message: ${error}`, 'error', 'cocoscript'))
      break
    default:
      break
  }
}

const onScriptMessage = ({ status, timestamp }) => (dispatch) => {
  switch (status) {
    case STATUS_STOP:
      dispatch(addLog('Script stop --', 'info', 'cocoscript'))
      break
    case STATUS_DONE:
      dispatch(onScriptEnds(timestamp))
      dispatch(addLog('Script ends --', 'info', 'cocoscript'))
      break
    default:
      break
  }
}

const onTempMessage = ({ parsedMsg, timestamp }) => (dispatch, getState) => {
  const {
    lastTempUpdate
  } = getState().devices
  // Temp message without data
  if (!parsedMsg.data) return
  if (lastTempUpdate && timestamp && lastTempUpdate > timestamp) {
    console.log('Temp message discarded: ', parsedMsg.data)
    return
  }

  dispatch(updateLastTempUpdate(timestamp))
  if (parsedMsg.data.heater) {
    dispatch(onSingleTempMessage(parsedMsg.data))
  } else {
    dispatch(onMultipleTempsMessage(parsedMsg.data))
  }
}

const onLogMessage = ({ payload, timestamp }) => (dispatch) => {
  dispatch(
    addLog(
      payload.message,
      payload.level,
      'cocoscript'
    )
  )

  // Set editor status as not running
  if (payload.code === ERROR_PAYLOAD_TOO_BIG) dispatch(onScriptEnds(timestamp))
}

export const subscribeToDevice = (deviceId, URL) => (dispatch, getState) => {
  return socketClient.subscribeToDevice(deviceId)
    .then((roomInfo) => {
      const { apiHealthData } = getState().log
      for (const [name, value] of Object.entries(apiHealthData || {})) {
        dispatch(addLog(`[api] ${name}: ${value}`, 'out', 'cocoscript'))
      }

      if (roomInfo) {
        const { script } = roomInfo
        if (script && script.last_script_exec && script.timestamp) {
          if (script.done) {
            dispatch(addLog(`Latest executed script (${new Date(script.timestamp)})`, 'out', 'cocoscript'))
            if (script.last_script_exec === '{"type":"script","status":"stop"}') {
              dispatch(addLog('\n Latest script was stopped!', 'code', 'cocoscript'))
            } else {
              const formattedScript = cleanNotifyMe(script.last_script_exec)
              dispatch(addLog('\n' + formattedScript, 'code', 'cocoscript'))
            }
          } else {
            dispatch(addLog(`There is an active execution (${new Date(script.timestamp)})`, 'out', 'cocoscript'))
            dispatch(updateScript(cleanNotifyMe(script.last_script_exec)))
            dispatch(updateEditorStatus(EDITOR_STATUS.RUNNING))
          }
        }
      }

      if (URL) browserHistory.push(URL)

      dispatch(updateConnecting(false))
      return true
    })
    .catch(err => { throw err })
}

export const selectDevice = (deviceId) => async (dispatch, getState) => {
  const {
    devices: { devices },
    auth: { user }
  } = getState()
  const loggedUserEmail = user.email

  const device = devices.find(d => d.id === deviceId)
  const deviceUserEmail = device.experiment?.user?.email ?? ''
  const layoutName = device.cartridge

  switch (device.status) {
    case DEVICE_STATUS_WAITING:
      if (loggedUserEmail === deviceUserEmail) {
        dispatch(updateConnecting(true))

        const { body } = await apiClient.get(`/electrode-layouts/${layoutName}`)
        dispatch(loadElectrodeLayout(body))
        dispatch(updateSelectedDeviceId(deviceId))
        dispatch(updateExperiment({ ...device.experiment }))
        dispatch(subscribeToDevice(deviceId, ANACONDA_URL))

        setTimeout(() => {
          socketClient.sendMessage({
            type: TYPE_DEVICE,
            status: DEVICE_STATUS_BUSY,
            experiment: {
              ...device.experiment,
              startedAt: Date.now()
            },
            step: 'waiting-connect'
          })
        }, 500)
      } else {
        // @TODO: What to do when user logged is different from waited user
      }
      break
    case DEVICE_STATUS_BUSY:
      if (loggedUserEmail === deviceUserEmail) {
        dispatch(updateConnecting(true))
        const { body } = await apiClient.get(`/electrode-layouts/${layoutName}`)
        dispatch(loadElectrodeLayout(body))
        dispatch(updateSelectedDeviceId(deviceId))
        dispatch(subscribeToDevice(deviceId, ANACONDA_URL))
      } else {
        // @TODO: What to do when user logged is different from runner user
      }
      break
    case DEVICE_STATUS_AVAILABLE: {
      dispatch(updateConnecting(true))

      const { body } = await apiClient.get(`/electrode-layouts/${layoutName}`)
      const experiment = { user }

      dispatch(updateExperiment(experiment))
      dispatch(loadElectrodeLayout(body))
      dispatch(updateSelectedDeviceId(deviceId))

      dispatch(subscribeToDevice(deviceId, PROJECT_SELECTION_URL))
      setTimeout(() => {
        socketClient.sendMessage({
          type: TYPE_DEVICE,
          status: DEVICE_STATUS_BUSY,
          experiment,
          step: 'setup'
        })
      }, 500)
      break
    }
  }
}

export const addOrUpdateDevice = (newDevice) => (dispatch, getState) => {
  const {
    devices: {
      selectedDeviceId,
      devices
    }
  } = getState()

  const previousDevice = devices.find(d => d.id === newDevice.id)

  if (previousDevice) {
    const isTheCurrentDevice = newDevice.id === selectedDeviceId
    // Someone finishes the experiment from anaconda-pi
    const finishedFromDevice =
      previousDevice.connected &&
      newDevice.status === DEVICE_STATUS_AVAILABLE &&
      isTheCurrentDevice

    if (finishedFromDevice) {
      window.alert('The experiment was finished from the device. We\'re gonna redirect you to the devices list.')
      browserHistory.push(instrumentsUrl(previousDevice.workspace))
    }
    dispatch(updateDevice(newDevice))
  } else {
    dispatch(addDevice(newDevice))
  }
}

export const startExperiment = () => (dispatch, getState) => {
  const state = getState()
  const device = currentDeviceSelector(state)
  const currentProject = currentProjectSelector(state)

  const experiment = {
    ...device.experiment,
    name: 'experiment-' + Date.now(),
    project: currentProject
      ? {
          name: currentProject.name,
          id: currentProject.id
        }
      : null,
    startedAt: Date.now(),
    user: state.auth.user
  }

  dispatch(updateExperiment(experiment))
  socketClient.sendMessage({
    type: TYPE_DEVICE,
    status: DEVICE_STATUS_BUSY,
    experiment,
    step: 'running'
  })
}

export const releaseDevice = () => (dispatch) => {
  dispatch(leaveDevice())
  socketClient.sendMessage({
    type: TYPE_DEVICE,
    status: DEVICE_STATUS_AVAILABLE
  })
}

export const leaveDevice = () => (dispatch, getState) => {
  const {
    devices: {
      devices,
      selectedDeviceId
    }
  } = getState()

  const device = devices.find(d => d.id === selectedDeviceId)
  if (device && device.cartridge && device.id) {
    dispatch(updateSelectedDeviceId(''))
    dispatch(resetTempData())
    socketClient.unsubscribeFromDevice(selectedDeviceId)
  }
}

export const removeDeviceFromOrganization = (id) => async (dispatch) => {
  dispatch(updateLoading(true))

  try {
    await apiClient.del(`/devices/${id}`)

    dispatch(deleteDevice(id))

    toast.success('The instrument has been deleted successfully')
  } catch (e) {
    Sentry.captureException(e)
    toast.error('Something went wrong trying to delete the device')
  } finally {
    dispatch(updateLoading(false))
  }
}

// actions
export const updateSelectedDeviceId = (selectedDeviceId) => ({
  type: UPDATE_SELECTED_DEVICE_ID,
  payload: { selectedDeviceId }
})

export const updateDevice = (device) => ({
  type: UPDATE_DEVICE,
  payload: { device }
})

export const updateDevices = (devices) => ({
  type: UPDATE_DEVICES,
  payload: { devices }
})

export const addDevice = (device) => ({
  type: ADD_DEVICE,
  payload: { device }
})

export const deleteDevice = (deviceId) => ({
  type: DELETE_DEVICE,
  payload: { deviceId }
})

export const clearDevices = () => ({
  type: CLEAR_DEVICES
})

export const updateConnecting = (connecting) => ({
  type: DEVICES_UPDATE_CONNECTING,
  payload: { connecting }
})

export const updateLoading = (loading) => ({
  type: DEVICES_UPDATE_LOADING,
  payload: { loading }
})

export const updateLastTempUpdate = (lastTempUpdate) => ({
  payload: { lastTempUpdate },
  type: UPDATE_LAST_TEMP_UPDATE
})

const initialState = {
  selectedDeviceId: '',
  devices: [
    // {name: "paul", user: "paul", createdAt: "2018-03-08T16:13:27.323Z", updatedAt: "2018-03-08T16:13:27.323Z", id: 1}
  ],
  connecting: false,
  loading: true,
  lastTempUpdate: 0,
  previousMessageType: null,
  selectedDeviceRuns: [],
  selectedRunId: null,
  loadingRuns: false
}

// Reducer
export default function reducer (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case UPDATE_DEVICES:
    case DEVICES_UPDATE_CONNECTING:
    case DEVICES_UPDATE_LOADING:
    case UPDATE_SELECTED_DEVICE_ID:
    case UPDATE_LAST_TEMP_UPDATE:
    case UPDATE_PREVIOUS_MESSAGE_TYPE:
      return {
        ...state,
        ...payload
      }
    case ADD_DEVICE: {
      const { device } = payload
      return {
        ...state,
        devices: state.devices.slice().concat([device])
      }
    }
    case UPDATE_DEVICE: {
      const updatedDevice = payload.device
      const deviceToUpdateIndex = state.devices.map(d => d.id).indexOf(updatedDevice.id)
      return {
        ...state,
        devices: [
          ...state.devices.slice(0, deviceToUpdateIndex),
          updatedDevice,
          ...state.devices.slice(deviceToUpdateIndex + 1)
        ]
      }
    }
    case DELETE_DEVICE: {
      const { deviceId } = payload
      const index = state.devices.map(d => d.id).indexOf(deviceId)

      return {
        ...state,
        devices: [
          ...state.devices.slice(0, index),
          ...state.devices.slice(index + 1)
        ]
      }
    }
    case CLEAR_DEVICES:
      return {
        ...state,
        devices: []
      }
    default:
      return state
  }
}
