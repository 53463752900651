import React, { useRef } from 'react'
import {
  Button,
  ConfirmModal,
  TextArea
} from '@miroculus/nucleo'

const FinishModal = ({
  onConfirmClick,
  onCancelClick,
  feedback,
  onFeedbackChange
}) => {
  const cancelRef = useRef(null)

  return (
    <ConfirmModal
      level='info'
      label='If you finish the experiment, your progress will be lost.'
      description='Could you leave use some feedback?'
      leastDestructiveRef={cancelRef}
    >
      <TextArea
        placeholder='Tell us how was everything ...'
        value={feedback}
        onChange={(evt) => { onFeedbackChange(evt.target.value) }}
        maxLength={1000}
      />
      <Button
        ref={cancelRef}
        size='small'
        onClick={onCancelClick}
        flavor='secondary'
      >
        Cancel
      </Button>
      <Button
        size='small'
        onClick={onConfirmClick}
        flavor='primary'
        data-testid='confirm-button'
      >
        Finish
      </Button>
    </ConfirmModal>
  )
}

export default FinishModal
