import React, { useCallback, useState, useEffect } from 'react'
import { Button, Input, Modal, Select } from '@miroculus/nucleo'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames/bind'
import styles from './SendProtocolModal.scss'
import { useParams, useHistory } from 'react-router-dom'
import { DASHBOARD_URL } from 'cons/routes'
import { FormField } from 'components'
import socketClient from 'socketClient'
import {
  getLoadingOrganizations,
  getCurrentOrganization,
  getOrganizationFromTeam,
  getOrganizations
} from '../reduxModules/organizations/selectors'
import { switchToOrganization } from '../reduxModules/organizations'
import { sendProtocolToDevice } from '../reduxModules/protocols'

const cx = classNames.bind(styles)

const SendProtocolModal = () => {
  const id = parseInt(useParams().id, 10)
  const history = useHistory()

  const protocol = useSelector(({ protocols }) =>
    protocols.protocols.find((p) => p.id === id)
  )

  const loadingOrganizations = useSelector(getLoadingOrganizations)
  const organization = useSelector(getCurrentOrganization)
  const organizations = useSelector(getOrganizations)

  const [searchValue, setSearchValue] = useState('')

  const handleClose = useCallback(() => {
    history.goBack()
  }, [])

  const dispatch = useDispatch()

  useEffect(() => {
    let orgSlug

    // This is the way to know if the org was loaded
    if (!organization.name) {
      const org = getOrganizationFromTeam(organizations, protocol?.team)
      orgSlug = org?.slug
      if (orgSlug) {
        dispatch(switchToOrganization(orgSlug))
        socketClient.subscribeToDevices(orgSlug)
      }
    }

    return () => {
      if (orgSlug) {
        socketClient.unsubscribeFromDevices(orgSlug)
      }
    }
  }, [organization.name])

  const { devices } = organization

  const handleSend = useCallback(() => {
    const device = devices.find(({ givenName }) => givenName === searchValue)
    dispatch(sendProtocolToDevice(id, device.id))
    history.push(DASHBOARD_URL)
  }, [devices, searchValue, dispatch])

  const options = devices.map(({ givenName, status }) => ({
    value: givenName,
    modifier: `(${status})`,
    disabled: status !== 'Available'
  }))

  const canSend = !!devices.find(
    ({ givenName, status }) =>
      givenName === searchValue && status === 'Available'
  )

  const organizationName = (!loadingOrganizations && organization.name) || 'Fetching...'

  return (
    <Modal label='SendProtocolModal' onClose={handleClose}>
      <section className={cx('modal-content')}>
        <h1>Send Protocol to Device</h1>
        <FormField title='Protocol name'>
          <Input frozen value={protocol?.name} />
        </FormField>
        <FormField title='Organization name'>
          <Input frozen value={organizationName} />
        </FormField>
        <h3 className={cx('subtitle')}>Send To</h3>
        <div className={cx('device-name')}>
          <FormField title='Device name'>
            <div className={cx('select')}>
              <Select
                contained
                name='deviceName'
                options={options}
                placeholder='Select a device'
                value={searchValue}
                onChange={setSearchValue}
              />
            </div>
          </FormField>
        </div>
        <Button size='small' onClick={handleSend} disabled={!canSend}>
          Send Protocol
        </Button>
      </section>
    </Modal>
  )
}

export default SendProtocolModal
