import React from 'react'
import classNames from 'classnames/bind'
import styles from './ProtocolInfoPanel.scss'
import PropTypes from 'prop-types'
import {
  Button,
  SendIcon,
  DuplicateIcon,
  EditIcon,
  RouteIcon,
  Label,
  DeleteIcon,
  ReagentInfo
} from '@miroculus/nucleo'
import {
  editProtocolUrl,
  copyProtocolUrl,
  sendProtocolUrl,
  protocolRouteUrl
} from 'cons/routes'
import { useParams } from 'react-router-dom'

const cx = classNames.bind(styles)

const SmallSecondaryButton = (props) => (
  <Button flavor='secondary' size='small' {...props} />
)

const ProtocolInfoPanel = ({
  reagents,
  name,
  description,
  id,
  team,
  author,
  createdAt,
  time,
  permissions,
  onDelete
}) => {
  const { teamId: teamToCopy } = useParams()

  return (
    <aside className={cx('container')}>
      <section className={cx('main-info')}>
        <Label>Protocol Name</Label>
        <h2>{name}</h2>
        <Label>Total run time</Label>
        <h2>{time}</h2>
        <div className={cx('two-columns')}>
          <Label>Author</Label>
          <p>{author}</p>
          <Label>Created Date</Label>
          <p>
            {new Date(createdAt).toLocaleDateString(
              'en-US',
              { year: 'numeric', month: 'short', day: 'numeric' }
            )}
          </p>
        </div>
      </section>
      <section className={cx('description')}>
        <Label>Description</Label>
        <p>{description}</p>
      </section>
      <section className={cx('reagents')}>
        <Label>Reagents</Label>
        <ul>
          {reagents.map((reagent, key) => (
            <li className={cx('reagent')} key={key}>
              <ReagentInfo
                name={reagent.description}
                volume={reagent.volume}
                dropgloss={reagent.jacketVol}
              />
            </li>
          ))}
        </ul>
      </section>
      <section className={cx('buttons')}>
        {permissions.canSeeRoutes && (
          <SmallSecondaryButton to={protocolRouteUrl(team, id)}>
            <RouteIcon /> Routing
          </SmallSecondaryButton>
        )}
        {permissions.canCopy && (
          <SmallSecondaryButton to={copyProtocolUrl(teamToCopy, id)}>
            <DuplicateIcon /> Copy
          </SmallSecondaryButton>
        )}
        {permissions.canEdit && (
          <SmallSecondaryButton onClick={onDelete}>
            <DeleteIcon /> Delete
          </SmallSecondaryButton>
        )}
        {permissions.canEdit && (
          <SmallSecondaryButton to={editProtocolUrl(team, id)}>
            <EditIcon /> Edit
          </SmallSecondaryButton>
        )}
        {permissions.canSend && (
          <SmallSecondaryButton to={sendProtocolUrl(team, id)}>
            <SendIcon /> Send
          </SmallSecondaryButton>
        )}
      </section>
    </aside>
  )
}

ProtocolInfoPanel.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  team: PropTypes.number.isRequired,
  reagents: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      volume: PropTypes.number.isRequired,
      jacketVol: PropTypes.number
    })
  ),
  name: PropTypes.string,
  description: PropTypes.string,
  permissions: PropTypes.shape({
    canSeeRoutes: PropTypes.bool,
    canCopy: PropTypes.bool,
    canEdit: PropTypes.bool,
    canSend: PropTypes.bool
  }).isRequired,
  author: PropTypes.string,
  time: PropTypes.string,
  createdAt: PropTypes.number,
  onDelete: PropTypes.func
}

ProtocolInfoPanel.defaultProps = {
  reagents: [],
  permissions: {}
}

export default ProtocolInfoPanel
