import React, { useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Breadcrumb,
  Header as NucleoHeader,
  Button,
  MenuIcon
} from '@miroculus/nucleo'
import styles from './Header.scss'
import classnames from 'classnames/bind'
import { BreadcrumbContext } from 'components'
import { protocolUrl } from 'cons/routes'

const cx = classnames.bind(styles)

const Header = ({ onSidebarClose }) => {
  const { levels } = useContext(BreadcrumbContext)
  const adjustMargin = !!useRouteMatch(protocolUrl(':teamId', ':id'))

  return (
    <div className={cx('container')}>
      <NucleoHeader>
        <div className={cx('buttonAndBreadcrumbs', { adjustMargin })}>
          <Button flavor='menu' size='xsmall' onClick={onSidebarClose}>
            <MenuIcon />
          </Button>
          <Breadcrumb levels={levels} />
        </div>
      </NucleoHeader>
    </div>
  )
}

Header.propTypes = {
  onSidebarClose: PropTypes.func.isRequired
}

export default Header
