import React, { useState, useMemo } from 'react'
import { useDebouncedValue } from 'hooks'
import { DynamicTable, HeadingWithButton, ProtocolFilters } from 'components'

const ItemsTab = (getTabData, filterItems) => ({
  loading,
  items,
  ...props
}) => {
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebouncedValue(searchText, 500)

  const handleSearchTextChange = (evt) => {
    setSearchText(evt.currentTarget.value)
  }

  const filteredItems = useMemo(
    () => filterItems(debouncedSearchText, items),
    [debouncedSearchText, items]
  )
  const data = getTabData(filteredItems, props)

  return (
    <>
      <HeadingWithButton
        title={`Manage ${data.title}`}
        buttonTextElement={data.button?.text}
        buttonLinkTo={data.button?.link}
        titleAnnex={data.quickInfo}
        titleAnnexAlignment={!data.button ? 'left' : 'right'}
        showSmallTitle
      />
      <ProtocolFilters
        searchText={searchText}
        onSearchTextChange={handleSearchTextChange}
      />
      {!loading && (
        <DynamicTable
          columnNames={data.columnNames}
          body={data.currentTabList}
          withActions={data.withActions}
        />
      )}
    </>
  )
}

export default ItemsTab
