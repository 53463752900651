// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MinimapContainer_container__3iKEF{width:100%;height:100%;padding-left:2.375rem;display:flex;align-items:center}.MinimapContainer_minimap__3CCwz{flex:1;width:100%;height:100%}.MinimapContainer_buttons__Fvn3t{width:10.875rem;display:flex;justify-content:center}.MinimapContainer_buttons__Fvn3t button{margin:.125rem}.MinimapContainer_headerOverlay__FOsJC{pointer-events:none;position:absolute;left:0;top:0;width:100%;height:100%;background:rgba(0,0,0,.05);-webkit-clip-path:polygon(0 0, 0 100%, 2.375rem 100%, 2.375rem 0, calc(100% - 10.875rem) 0, calc(100% - 10.875rem) 100%, 2.375rem 100%, 2.375rem 100%, 100% 100%, 100% 0%);clip-path:polygon(0 0, 0 100%, 2.375rem 100%, 2.375rem 0, calc(100% - 10.875rem) 0, calc(100% - 10.875rem) 100%, 2.375rem 100%, 2.375rem 100%, 100% 100%, 100% 0%)}", ""]);
// Exports
exports.locals = {
	"container": "MinimapContainer_container__3iKEF",
	"minimap": "MinimapContainer_minimap__3CCwz",
	"buttons": "MinimapContainer_buttons__Fvn3t",
	"headerOverlay": "MinimapContainer_headerOverlay__FOsJC"
};
module.exports = exports;
