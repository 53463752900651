// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OrganizationMemberModal_modal-content__OlQen h1{text-transform:capitalize}.OrganizationMemberModal_modal-content__OlQen .OrganizationMemberModal_buttons__3QTF_{display:flex;flex-direction:row-reverse;justify-content:center}.OrganizationMemberModal_modal-content__OlQen .OrganizationMemberModal_buttons__3QTF_ button{margin-top:3rem;margin-right:1rem}.OrganizationMemberModal_modal-content__OlQen>div:first-child{margin-top:1.5rem}.OrganizationMemberModal_copy-link__3gHGV{padding-top:1rem;display:flex;align-items:baseline}.OrganizationMemberModal_copy-link__3gHGV input{margin-right:1.5rem}", ""]);
// Exports
exports.locals = {
	"modal-content": "OrganizationMemberModal_modal-content__OlQen",
	"buttons": "OrganizationMemberModal_buttons__3QTF_",
	"copy-link": "OrganizationMemberModal_copy-link__3gHGV"
};
module.exports = exports;
