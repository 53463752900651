import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Prompt, useHistory } from 'react-router-dom'
import { useConfirm } from 'components'

function NavigationBlock ({ message, exceptUrl }) {
  const history = useHistory()
  const { confirm } = useConfirm()
  const [confirmed, setConfirmed] = useState(false)
  const [blockedLocation, setBlockedLocation] = useState(null)

  // This effect implements the blocking of navigation outside react-router
  // i.e native browser navigation
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)

    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])

  // This effect is to navigate to the blocked location once it's confirmed
  // by the user
  useEffect(() => {
    if (confirmed && blockedLocation) {
      history.push(blockedLocation.pathname)
    }
  }, [confirmed, blockedLocation])

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = message
    return message
  }

  return (
    <Prompt message={(location) => {
      if (!confirmed && location.pathname !== exceptUrl) {
        setBlockedLocation(location)
        confirm(message, () => setConfirmed(true))
        return false
      }
      return true
    }}
    />
  )
}

NavigationBlock.propTypes = {
  message: PropTypes.string.isRequired,
  exceptUrl: PropTypes.string
}

export default NavigationBlock
