
export const ACCOUNT_SETTINGS_URL = '/account'
export const SIGNUP_URL = '/signup'
export const LOGOUT_URL = '/logout'
export const LOGIN_URL = '/login'
export const DASHBOARD_URL = '/'
export const ANACONDA_URL = '/anaconda'
export const PROTOCOL_URL = '/palette'
export const PROJECT_SELECTION_URL = '/project-selection'
export const MEMBERS_URL = '/members'
export const NEW_MEMBER_URL = '/members/new'
export const EDIT_MEMBER_URL = '/members/edit'
export const FORGOT_PASSWORD_URL = '/forgot-password'
export const RESET_PASSWORD_URL = '/reset-password'
export const RECENT_URL = '/recent'
export const DRAFTS_URL = '/drafts'
export const ORGANIZATION_URL = '/organization'
export const ORGANIZATION_SETTINGS_URL = '/settings'
export const TEAM_URL = '/team'
export const INSTRUMENTS_URL = '/instruments'
export const CREATE_ORGANIZATION_URL = '/create-organization'
export const NEW_ORGANIZATION_MEMBER_URL = '/members/new'
export const EDIT_ORGANIZATION_MEMBER_URL = '/members/edit'
export const ORGANIZATION_INVITE_URL = '/org-invites'
export const NEW_TEAM_URL = '/teams/new'
export const EDIT_TEAM_URL = '/teams/edit'
export const DELETE_TEAM_URL = '/teams/delete'

export const organizationUrl = (slug) => `${ORGANIZATION_URL}/${slug}`
export const organizationSettingsUrl = (slug) => `${organizationUrl(slug)}${ORGANIZATION_SETTINGS_URL}`
export const newOrganizationMemberUrl = (slug) => `${organizationUrl(slug)}${NEW_ORGANIZATION_MEMBER_URL}`
export const editOrganizationMemberUrl = (slug, id) => `${organizationUrl(slug)}${EDIT_ORGANIZATION_MEMBER_URL}/${id}`
export const teamUrl = (id) => `${TEAM_URL}/${id}`
export const teamUrlWithVisibility = (id, visibility) => `${TEAM_URL}/${id}/${visibility}`
export const instrumentsUrl = (slug) => `${organizationUrl(slug)}${INSTRUMENTS_URL}`
export const protocolUrl = (teamId, id) => `${teamUrl(teamId)}${PROTOCOL_URL}/${id}`
export const editProtocolUrl = (teamId, id = 'new') => `${protocolUrl(teamId, id)}/edit`
export const protocolRouteUrl = (teamId, id) => `${protocolUrl(teamId, id)}/route`
export const saveProtocolUrl = (teamId, id = 'new') => `${protocolUrl(teamId, id)}/save`
export const copyProtocolUrl = (teamId, from) => `${teamUrl(teamId)}${PROTOCOL_URL}/from/${from}`
export const sendProtocolUrl = (teamId, id) => `${protocolUrl(teamId, id)}/send`
export const newOrganizationTeamUrl = (slug) => `${organizationUrl(slug)}${NEW_TEAM_URL}`
export const editOrganizationTeamUrl = (slug, id) => `${organizationUrl(slug)}${EDIT_TEAM_URL}/${id}`
export const deleteOrganizationTeamUrl = (slug, id) => `${organizationUrl(slug)}${DELETE_TEAM_URL}/${id}`

export const editMemberUrl = id => `${EDIT_MEMBER_URL}/${id}`
export const instrumentDetailsUrl = (organizationSlug, instrumentId) => `${instrumentsUrl(organizationSlug)}/${instrumentId}`
export const instrumentRunDetailsUrl = (organizationSlug, instrumentId, runId) => `${instrumentDetailsUrl(organizationSlug, instrumentId)}/runs/${runId}`
