import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Modal, Select } from '@miroculus/nucleo'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames/bind'
import browserHistory from 'browserHistory'
import { useParams } from 'react-router-dom'
import { organizationSettingsUrl } from 'cons/routes'
import { FormField, LoadingWithText, UserAccountHeader } from 'components'
import { MEMBER_ROLES } from 'reduxModules/workspaces'
import {
  getInviteLinkToOrganization,
  inviteUserToOrganization,
  updateOrganizationMember,
  updateInviteLink
} from 'reduxModules/organizations'
import { getSendingInvitation, getUpdatingOrganization, membersSelector }
  from 'reduxModules/organizations/selectors'
import { canSubmit } from 'utils/forms'
import styles from './OrganizationMemberModal.scss'

const cx = classNames.bind(styles)

const MEMBER_ROLE_OPTIONS = Object.entries(MEMBER_ROLES)
  .map(([value, label]) => ({ value, label }))

const SUBMIT_WAY_LINK = 'SUBMIT_WAY_LINK'
const SUBMIT_WAY_EMAIL = 'SUBMIT_WAY_EMAIL'

const OrganizationMemberForm = ({
  id,
  organization,
  createdAt,
  name,
  email = '',
  role = 'collaborator'
}) => {
  const dispatch = useDispatch()
  // a way to signal which button was used to submit
  const submitWay = useRef(SUBMIT_WAY_EMAIL)
  const inviteLink = useSelector(state => state.organizations.inviteLink)

  const onSubmit = async (values) => {
    if (id) {
      dispatch(updateOrganizationMember({
        memberId: id,
        role: values.role
      }))
      return browserHistory.push(organizationSettingsUrl(organization))
    }

    if (submitWay.current === SUBMIT_WAY_LINK) {
      await dispatch(
        getInviteLinkToOrganization(values.email, values.role, organization)
      )
    } else {
      await dispatch(
        inviteUserToOrganization(values.email, values.role, organization)
      )
      browserHistory.push(organizationSettingsUrl(organization))
    }
  }

  const formik = useFormik({
    initialValues: { email, role },
    onSubmit
  })

  const emailProps = formik.getFieldProps('email')
  const roleProps = formik.getFieldProps('role')

  const handleRoleChange = useCallback((value) => {
    formik.setFieldValue('role', value)
  }, [])

  const handleSubmit = (e) => {
    if (e.nativeEvent.submitter) {
      // Figure out which of the 2 submit buttons was the one used for submission
      submitWay.current = e.nativeEvent.submitter.name
    }
    formik.handleSubmit(e)
  }

  useEffect(() => {
    // clear invite link when closing the modal
    return () => {
      dispatch(updateInviteLink(null))
    }
  }, [])

  if (inviteLink) {
    return <CopyInviteLink inviteLink={inviteLink} />
  }

  return (
    <form className={cx('modal-content')} onSubmit={handleSubmit}>
      {id
        ? <UserAccountHeader userFullName={name} joinedDate={createdAt} />
        : <h1>Invite new user</h1>}
      {id && (
        <FormField title='Full name'>
          <Input frozen value={name} />
        </FormField>
      )}
      <FormField title='Email address'>
        <Input
          {...emailProps}
          type='email'
          placeholder='Enter an email address to invite'
          frozen={!!id}
        />
      </FormField>
      <FormField title='User role'>
        <Select
          {...roleProps}
          onChange={handleRoleChange}
          options={MEMBER_ROLE_OPTIONS}
        />
      </FormField>
      <div className={cx('buttons')}>
        <Button
          type='submit'
          name={SUBMIT_WAY_EMAIL}
          size='small'
          disabled={!canSubmit(formik)}
        >
          {id
            ? 'Update user'
            : 'Invite via email'}
        </Button>
        {!id && (
          <Button
            type='submit'
            name={SUBMIT_WAY_LINK}
            size='small'
            disabled={!canSubmit(formik)}
            flavor='secondary'
          >
            Copy invite link
          </Button>
        )}
      </div>
    </form>
  )
}

OrganizationMemberForm.propTypes = {
  email: PropTypes.string,
  role: PropTypes.string,
  organization: PropTypes.string.isRequired,
  name: PropTypes.string,
  id: PropTypes.number,
  createdAt: PropTypes.number
}

const OrganizationMemberModal = () => {
  const { orgSlug, id } = useParams()
  const memberId = parseInt(id, 10)

  const organizationMember = useSelector((state) =>
    membersSelector(state).find((member) => member.id === memberId) ?? {}
  )

  const handleClose = useCallback(() => {
    browserHistory.push(organizationSettingsUrl(orgSlug))
  }, [])

  const sendingInvitation = useSelector(getSendingInvitation)
  const updatingOrganization = useSelector(getUpdatingOrganization)

  if (sendingInvitation || updatingOrganization) {
    return (
      <LoadingWithText
        message={sendingInvitation
          ? 'Sending invitation...'
          : 'Updating member...'}
      />
    )
  }

  return (
    <Modal label='OrganizationMemberModal' onClose={handleClose}>
      <OrganizationMemberForm
        email={organizationMember.email}
        name={organizationMember.name}
        role={organizationMember.role}
        organization={orgSlug}
        id={organizationMember.name ? memberId : undefined}
        createdAt={organizationMember.createdAt}
      />
    </Modal>
  )
}

export default OrganizationMemberModal

const CopyInviteLink = ({ inviteLink }) => {
  const inputRef = useRef()

  // auto-select the input so the user can easily copy
  useEffect(() => {
    inputRef.current.select()
  }, [inputRef])

  const handleClipboardClick = () => {
    inputRef.current.select()
    document.execCommand('copy')
  }

  return (
    <div className={cx('modal-content')}>
      <h1>Invitation link generated</h1>
      <p>
        A link has been generated, please use the copy button to share the link. This link is unique to the requested email.
      </p>
      <div className={cx('copy-link')}>
        <Input ref={inputRef} readOnly type='text' value={inviteLink} />
        <Button
          onClick={handleClipboardClick}
        >
          Copy
        </Button>
      </div>
    </div>
  )
}
