import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Spinner as LoadingSpinner,
  MiroculusIcon,
  Logo,
  Input
} from '@miroculus/nucleo'
import { unifiedVersion } from 'utils'
import { HeadingWithButton, InstrumentCard, useBreadcrumb } from 'components'
import { useParams } from 'react-router-dom'
import { debounce } from 'utils/forms'
import styles from './InstrumentDashboard.scss'

import classnames from 'classnames/bind'

const cx = classnames.bind(styles)

const WHITE_COLOR = '#ffffff'

const InstrumentDashboard = ({
  loading,
  instruments,
  connecting,
  onInstrumentClick,
  listMode,
  organizationName,
  organizationSlug,
  canAccessAnaconda
}) => {
  const renderConnectingPanel = () => {
    if (connecting) {
      return (
        <div className={cx('loadingContainer')}>
          <Logo color={WHITE_COLOR} width={98} height={98} animated infinite />
          <p>Connecting...</p>
        </div>
      )
    }
    return null
  }

  const renderSpinner = () => {
    if (loading) {
      return (
        <div className={cx('spinnerContainer')}>
          <LoadingSpinner />
        </div>
      )
    }
    return null
  }

  const renderNoInstrumentsMessage = () => {
    if (!loading && !instruments.length) {
      return (
        <div className={cx('noInstrumentsContainer')}>
          <h1>No connected instruments</h1>
        </div>
      )
    }
    return null
  }

  const renderInstruments = () => {
    if (loading || !instruments.length) return null
    const disabled = !canAccessAnaconda

    return (
      <section className={cx('instrumentsContainer', { disabled })}>
        {filteredInstruments.map(({
          id,
          connected,
          status,
          givenName,
          startedAt,
          by,
          firmware,
          software,
          anacondaPi
        }) => (
          <InstrumentCard
            key={`instrument-card-${id}`}
            data-testid={`instrument-card-${id}`}
            disabled={!connected || status === 'Disconnected'}
            onClick={canAccessAnaconda
              ? () => onInstrumentClick(id)
              : undefined}
            id={id}
            givenName={givenName}
            organizationName={organizationName}
            organizationSlug={organizationSlug}
            status={status}
            startedAt={startedAt}
            user={by}
            connected={connected}
            version={unifiedVersion(anacondaPi, software, firmware)}
            size={listMode ? 'large' : 'big'}
          />
        ))}
      </section>
    )
  }

  const renderHeader = (numberOfInstruments) => {
    if (!numberOfInstruments) {
      return null
    }

    const titleAnnex = (
      <span className={cx('container--header_annex')}>
        <MiroculusIcon />{numberOfInstruments} INSTRUMENTS
      </span>
    )

    return (
      <section key='instruments-header' className={cx('container--header')}>
        <HeadingWithButton title='Instruments' titleAnnex={titleAnnex} />
        <div className={cx('container--header_options')}>
          <h3>{`ORG ID: ${orgSlug}`}</h3>
        </div>
      </section>
    )
  }

  const { orgSlug } = useParams()

  useEffect(() => {
    setFilteredInstruments(instruments)
  }, [instruments])

  const [filteredInstruments, setFilteredInstruments] = useState(instruments)
  const updateFilteredInstruments = (filterByInstrumentName) => {
    if (filterByInstrumentName) {
      setFilteredInstruments(instruments?.filter(({ givenName }) => givenName.toLowerCase().includes(filterByInstrumentName.toLowerCase())))
    } else {
      setFilteredInstruments(instruments)
    }
  }

  useBreadcrumb([
    {
      text: organizationName,
      href: `/organization/${orgSlug}`
    },
    {
      text: 'Instruments'
    }
  ], [organizationName, orgSlug])

  return (
    <div className={cx('container')}>
      {renderHeader(instruments?.length)}
      {renderNoInstrumentsMessage()}
      <InstrumentsSearchBar onChange={updateFilteredInstruments} />
      {renderInstruments()}
      {renderSpinner()}
      {renderConnectingPanel()}
    </div>
  )
}

InstrumentDashboard.defaultProps = {
  instruments: [],
  connecting: false,
  loading: true,
  listMode: false,
  onInstrumentClick: () => {}
}

InstrumentDashboard.propTypes = {
  instruments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      anacondaPi: PropTypes.string,
      givenName: PropTypes.string,
      cartridge: PropTypes.string,
      by: PropTypes.string,
      experiment: PropTypes.object,
      order: PropTypes.number,
      status: PropTypes.oneOf([
        'Busy',
        'Available',
        'Complete',
        'Disconnected',
        'Missing',
        'Offline'
      ]),
      startedAt: PropTypes.number,
      connected: PropTypes.bool,
      firmware: PropTypes.string,
      software: PropTypes.string,
      workspace: PropTypes.string
    })
  ),
  connecting: PropTypes.bool,
  loading: PropTypes.bool,
  onInstrumentClick: PropTypes.func,
  listMode: PropTypes.bool,
  organizationName: PropTypes.string,
  organizationSlug: PropTypes.string,
  canAccessAnaconda: PropTypes.bool
}

export default InstrumentDashboard

const InstrumentsSearchBar = ({ onChange }) => {
  const [searchInstrumentName, setSearchInstrumentName] = useState('')
  const debouncedSearchTextChange = debounce(false, 500, onChange)

  return (
    <section key='instruments-search-bar' className={cx('instruments-search-bar')}>
      <Input
        name='search'
        value={searchInstrumentName}
        onChange={(event) => {
          setSearchInstrumentName(event.target.value)
          debouncedSearchTextChange(event.target.value)
        }}
        placeholder='Search by name...'
      />
    </section>
  )
}
