// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header_container__1ni9g{width:100%;height:60px}.Header_buttonAndBreadcrumbs__yKECI{display:flex;align-items:center}.Header_buttonAndBreadcrumbs__yKECI>button{margin-right:1.5rem}.Header_buttonAndBreadcrumbs__yKECI.Header_adjustMargin__YoxIE>button{margin-left:-1rem}", ""]);
// Exports
exports.locals = {
	"container": "Header_container__1ni9g",
	"buttonAndBreadcrumbs": "Header_buttonAndBreadcrumbs__yKECI",
	"adjustMargin": "Header_adjustMargin__YoxIE"
};
module.exports = exports;
