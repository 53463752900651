// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RunDetails_staticFieldsContainer__3Ql3o{display:grid;grid-template-columns:12.5rem 12.5rem 12.5rem;row-gap:1rem}.RunDetails_staticField__1YtZa{text-transform:uppercase;gap:.25rem;display:flex;flex-direction:column;align-items:flex-start;margin:.25rem 0}.RunDetails_staticField__1YtZa .RunDetails_label__C-GiO{color:var(--color-control-text);font-size:var(--font-size-small);font-weight:500}.RunDetails_form__1X9wo{text-align:left}.RunDetails_form__1X9wo .RunDetails_buttons__1yZkB{display:flex;gap:1rem;justify-content:center;margin-top:2rem}.RunDetails_form__1X9wo .RunDetails_field__3VQmL{position:relative}.RunDetails_form__1X9wo .RunDetails_field__3VQmL .RunDetails_errorMessage__22zIb{position:absolute;right:1rem;margin:0;color:var(--color-error);display:flex;height:100%;align-items:center}.RunDetails_form__1X9wo textarea{resize:none;min-height:8.75rem}.RunDetails_centered__2Ebee{width:100%;height:100%;display:flex;align-items:center;justify-content:center}", ""]);
// Exports
exports.locals = {
	"staticFieldsContainer": "RunDetails_staticFieldsContainer__3Ql3o",
	"staticField": "RunDetails_staticField__1YtZa",
	"label": "RunDetails_label__C-GiO",
	"form": "RunDetails_form__1X9wo",
	"buttons": "RunDetails_buttons__1yZkB",
	"field": "RunDetails_field__3VQmL",
	"errorMessage": "RunDetails_errorMessage__22zIb",
	"centered": "RunDetails_centered__2Ebee"
};
module.exports = exports;
