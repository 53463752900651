import React, { useState } from 'react'
import classnames from 'classnames/bind'
import styles from './Collapsible.scss'
import { useClientRect } from 'hooks'

const cx = classnames.bind(styles)

const Collapsible = ({
  open = true,
  onChange,
  header,
  children,
  ...props
}) => {
  const [show, toggle] = useState(open)
  const handleClick = () => {
    toggle(!show)
    onChange?.(!show)
  }
  const [ref, { height }] = useClientRect([children])

  return (
    <>
      <div
        data-testid={props['data-testid']}
        className={cx('header')}
        onClick={handleClick}
      >
        {header(show)}
      </div>
      <div
        className={cx('content', { show })}
        style={{ height: show ? height : 0 }}
      >
        <div ref={ref}>
          {children}
        </div>
      </div>
    </>
  )
}

export default Collapsible
