import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useRect } from '@reach/rect'
import { Tooltip } from '@miroculus/nucleo'
import classNames from 'classnames/bind'
import styles from './OverflownTooltipText.scss'

const cx = classNames.bind(styles)

const OverflownTooltipText = ({ tooltipLabel, children }) => {
  const contentRef = useRef(null)
  const contentRect = useRect(contentRef, true)

  const isOverflown = contentRef.current?.scrollWidth > contentRect?.width

  return (
    <div className={cx('overflown-text-container')} ref={contentRef}>
      {isOverflown
        ? (
          <Tooltip direction='bottom' label={tooltipLabel}>
            {children}
          </Tooltip>
          )
        : children}
    </div>
  )
}

OverflownTooltipText.propTypes = {
  tooltipLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default OverflownTooltipText
