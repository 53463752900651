import React from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import classnames from 'classnames/bind'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/browser'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Input } from '@miroculus/nucleo'
import { DASHBOARD_URL } from 'cons/routes'
import { AuthPage } from 'components'
import { canSubmit, getFirstError, getFieldError } from 'utils/forms'
import { createOrganization, loadOrganizations } from 'reduxModules/organizations'
import { useQuery } from 'hooks'
import browserHistory from 'browserHistory'
import styles from './CreateOrganization.scss'

const cx = classnames.bind(styles)

const CreateOrganizationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'The name is too short, try again')
    .required('A name is required')
})

const CreateOrganization = () => {
  const query = useQuery()
  const token = query.get('token')
  const dispatch = useDispatch()
  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      name: ''
    },
    onSubmit: async (values, actions) => {
      actions.setStatus(undefined)
      try {
        await dispatch(createOrganization(values.name, token))
        await dispatch(loadOrganizations())
        browserHistory.push(DASHBOARD_URL)
      } catch (e) {
        actions.setStatus(e.message)
        Sentry.captureException(e)
        toast.error('Something went wrong creating the organization. Please contact support.')
      }
    },
    validationSchema: CreateOrganizationSchema
  })

  if (!token) return <Redirect to={DASHBOARD_URL} />

  const errorToDisplay = getFirstError(formik)
  const statusMessage = errorToDisplay || formik.status

  return (
    <AuthPage
      additionalInfo={(
        <>
          You've been invited to create a new organization.<br />
          Please enter the organization name to get started:
        </>
      )}
    >
      <form className={cx('form')} onSubmit={formik.handleSubmit}>
        <Input
          {...formik.getFieldProps('name')}
          name='name'
          type='text'
          placeholder='Enter the organization name'
          error={getFieldError(formik, 'name')}
          autoFocus
        />
        <div className={cx('buttonContainer')}>
          <p className={cx('statusMessage')}>{statusMessage || <>&nbsp;</>}</p>
          <Button type='submit' disabled={!canSubmit(formik)}>Create organization</Button>
        </div>
      </form>
    </AuthPage>
  )
}

export default CreateOrganization
