import React from 'react'
import throttle from 'lodash.throttle'
import { ArrowLeftIcon, ArrowRightIcon, Button } from '@miroculus/nucleo'
import classNames from 'classnames/bind'
import styles from './MinimapContainer.scss'
import PropTypes from 'prop-types'

const cx = classNames.bind(styles)

// compute percentage of the minimap where clicked
const getPoint = (el, clientX) => {
  const { left, width } = el.getBoundingClientRect()
  return (clientX - left) / width
}

const MinimapContainer = ({ children, controlledRef }) => {
  const handleLeft = () => {
    const container = controlledRef.current
    if (!container) return
    container.scrollBy({
      left: -200,
      behavior: 'smooth'
    })
  }

  const handleRight = () => {
    const container = controlledRef.current
    if (!container) return
    container.scrollBy({
      left: 200,
      behavior: 'smooth'
    })
  }

  const scrollTo = (container, ratio) => {
    container.scrollTo({
      left: ratio * container.scrollWidth - container.clientWidth / 2,
      behavior: 'smooth'
    })
  }

  const handleMouseDown = (evt) => {
    // prevent text selection over the app
    evt.preventDefault()

    const el = evt.currentTarget
    scrollTo(controlledRef.current, getPoint(el, evt.clientX))

    const handleMouseMove = throttle(({ clientX }) => {
      scrollTo(controlledRef.current, getPoint(el, clientX))
    }, 100)

    const handleMouseUp = ({ clientX }) => {
      scrollTo(controlledRef.current, getPoint(el, clientX))
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  return (
    <>
      <div className={cx('headerOverlay')} />
      <div className={cx('container')}>
        <div className={cx('minimap')} onMouseDown={handleMouseDown}>
          {children}
        </div>
        <div className={cx('buttons')}>
          <Button
            flavor='secondary'
            size='small'
            onClick={handleLeft}
            data-testid='minimap-left'
          >
            <ArrowLeftIcon />
          </Button>
          <Button
            flavor='secondary'
            size='small'
            onClick={handleRight}
            data-testid='minimap-right'
          >
            <ArrowRightIcon />
          </Button>
        </div>
      </div>
    </>
  )
}

MinimapContainer.propTypes = {
  children: PropTypes.node.isRequired,
  controlledRef: PropTypes.shape({
    current: PropTypes.instanceOf(window.HTMLElement)
  }).isRequired
}

export default MinimapContainer
