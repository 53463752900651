import { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { switchToOrganization } from 'reduxModules/organizations'
import { organizationUrl } from 'cons/routes'

/**
 * Wrapper component that load current organization into the store
 */
const OrganizationLoader = memo(({ children }) => {
  const dispatch = useDispatch()
  const currentOrganizationSlug = useRouteMatchOrganization()

  useEffect(() => {
    if (currentOrganizationSlug) {
      dispatch(switchToOrganization(currentOrganizationSlug))
    }
  }, [currentOrganizationSlug])

  return children
})

export default OrganizationLoader

export const useRouteMatchOrganization = () => useRouteMatch({
  path: [
    organizationUrl(':orgSlug')
  ],
  exact: false
})?.params?.orgSlug
