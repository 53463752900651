import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames/bind'
import { Modal } from '@miroculus/miroculus-ui'
import styles from './Layout.scss'
import { Header } from 'containers'
import { Spinner, Sidebar } from 'components'
import { hideModal } from 'reduxModules/ui/layout'
import { getCurrentModal } from 'reduxModules/ui/selectors'
import { getLoading } from 'reduxModules/auth/selectors'

const cx = classnames.bind(styles)

export const Layout = ({
  children,
  currentModal,
  loading,
  onHideModal
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(true)

  const handleSidebarClose = () => {
    setSidebarOpen(!sidebarOpen)
  }

  return (
    <div className={cx('container')}>
      <div>
        <Sidebar open={sidebarOpen} />
      </div>
      <div className={cx('body')}>
        <Header onSidebarClose={handleSidebarClose} />
        {!loading
          ? (
            <div className={cx('main')}>
              {children}
            </div>
            )
          : <Spinner />}
        {!!currentModal &&
          <div className={cx('modalContainer')}>
            <Modal onCloseButtonClick={onHideModal}>
              {currentModal}
            </Modal>
          </div>}
      </div>
    </div>
  )
}

Layout.propTypes = {
  currentModal: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  onHideModal: PropTypes.func
}

const mapStateToProps = (state) => ({
  loading: getLoading(state),
  currentModal: getCurrentModal(state)
})

const mapDispatchToProps = (dispatch) => ({
  onHideModal: () => { dispatch(hideModal()) }
})

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps
)(Layout))
