// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DeviceDetails_mainContainer__1tfp1{width:100%;height:100%;overflow:scroll}.DeviceDetails_loadingContainer__-jZEN{width:100%;height:100%}.DeviceDetails_subtitle__10Fky{font-size:var(--font-size-medium);color:var(--color-primary);margin:0 0 1.5rem 0;letter-spacing:1px;font-weight:500}.DeviceDetails_section__2t6iM{padding:1rem 2rem}.DeviceDetails_section__2t6iM.DeviceDetails_bordered__g8Zyv{border-bottom:1px solid var(--color-neutral-350)}.DeviceDetails_loadingContainer__-jZEN{display:flex;width:100%;height:100%;justify-content:center;align-items:center}.DeviceDetails_deviceId__1fqJt{color:var(--color-control-text);margin-top:.5rem}.DeviceDetails_deviceId__1fqJt h3{margin:0;letter-spacing:1px;font-weight:500;font-size:var(--font-size-medium)}.DeviceDetails_noRunsMessage__1XwSO{color:var(--color-control-text)}.DeviceDetails_headerWithButton__3w82I{display:flex;justify-content:space-between}.DeviceDetails_headerWithButton__3w82I a{text-decoration:none;display:flex;align-items:center;height:1rem}.DeviceDetails_headerWithButton__3w82I a svg{margin-right:.5rem}", ""]);
// Exports
exports.locals = {
	"mainContainer": "DeviceDetails_mainContainer__1tfp1",
	"loadingContainer": "DeviceDetails_loadingContainer__-jZEN",
	"subtitle": "DeviceDetails_subtitle__10Fky",
	"section": "DeviceDetails_section__2t6iM",
	"bordered": "DeviceDetails_bordered__g8Zyv",
	"deviceId": "DeviceDetails_deviceId__1fqJt",
	"noRunsMessage": "DeviceDetails_noRunsMessage__1XwSO",
	"headerWithButton": "DeviceDetails_headerWithButton__3w82I"
};
module.exports = exports;
