// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DeleteTeamModal_container__7W_Z9{padding:4rem 0 0}.DeleteTeamModal_container__7W_Z9 h1{text-transform:capitalize}.DeleteTeamModal_container__7W_Z9 h1.DeleteTeamModal_creating__3UyhP{text-transform:none}.DeleteTeamModal_container__7W_Z9 .DeleteTeamModal_buttons-container__1lbIs{justify-content:center;padding-top:2rem;width:100%;display:flex;gap:1rem}", ""]);
// Exports
exports.locals = {
	"container": "DeleteTeamModal_container__7W_Z9",
	"creating": "DeleteTeamModal_creating__3UyhP",
	"buttons-container": "DeleteTeamModal_buttons-container__1lbIs"
};
module.exports = exports;
