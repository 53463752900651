// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProtocolListWithHeader_container__9tFFJ{position:relative;padding:2rem 2.5rem;height:100%;background:var(--color-page-background);overflow-y:auto}", ""]);
// Exports
exports.locals = {
	"container": "ProtocolListWithHeader_container__9tFFJ"
};
module.exports = exports;
