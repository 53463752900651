import apiClient from '@miroculus/api-client'
import { updateWorkspaces } from 'reduxModules/workspaces'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/browser'
import browserHistory from 'browserHistory'
import { DASHBOARD_URL } from 'cons/routes'

const UPDATE_LOADING_ORGANIZATIONS = 'app/organizations/UPDATE_LOADING_ORGANIZATIONS'
const UPDATE_ORGANIZATIONS = 'app/organizations/UPDATE_ORGANIZATIONS'
const UPDATE_CURRENT_ORGANIZATION = 'app/organizations/UPDATE_CURRENT_ORGANIZATION'
const UPDATE_ORGANIZATION_MEMBERS = 'app/organizations/UPDATE_ORGANIZATION_MEMBERS'
const UPDATE_ORGANIZATION_TEAMS = 'app/organizations/UPDATE_ORGANIZATION_TEAMS'
const UPDATE_DELETING_TEAM = 'app/organizations/UPDATE_DELETING_TEAM'
const UPDATE_SENDING_INVITATION = 'app/organizations/UPDATE_SENDING_INVITATION'
const UPDATE_UPDATING_INVITATION = 'app/organizations/UPDATE_UPDATING_INVITATION'
const UPDATE_PENDING_INVITATIONS = 'app/organizations/UPDATE_PENDING_INVITATIONS'
const UPDATE_INVITE_LINK = 'app/organizations/UPDATE_INVITE_LINK'

export const loadOrganizations = () => async (dispatch) => {
  dispatch(updateLoadingOrganizations(true))
  try {
    const { body: organizations } = await apiClient.get('/organizations')
    dispatch(updateOrganizations(organizations))
    dispatch(updateWorkspaces(organizations.flatMap(o => o.teams)))
  } catch (e) {
    Sentry.captureException(e)
  }
  dispatch(updateLoadingOrganizations(false))
}

export const switchToOrganization = (orgSlug) => async (dispatch) => {
  dispatch(updateLoadingOrganizations(true))
  try {
    const { body: currentOrganization } = await apiClient.get(`/organizations/${orgSlug}`)
    dispatch(updateCurrentOrganization(currentOrganization))
  } catch (e) {
    Sentry.captureException(e)
  } finally {
    dispatch(updateLoadingOrganizations(false))
  }
}

export const inviteUserToOrganization = (email, role, orgSlug) => async (dispatch) => {
  dispatch(updateSendingInvitation(true))
  try {
    await apiClient.post(`/organization-invites/${orgSlug}`, { role, email })
    toast.success('The invitation to collaborate in this organization was sent successfully')
  } catch (e) {
    toast.error(`Error inviting new member: "${e.message}"`)
    throw e
  } finally {
    dispatch(updateSendingInvitation(false))
  }
}

export const getInviteLinkToOrganization = (email, role, orgSlug) => async (dispatch) => {
  dispatch(updateSendingInvitation(true))
  try {
    const { res } = await apiClient.post(
      `/organization-invites/${orgSlug}/link`,
      { role, email }
    )
    dispatch(updateInviteLink(await res.text()))
    toast.success('The invitation link has been generated successfully.')
  } catch (e) {
    toast.error(`Error inviting new member: "${e.message}"`)
    throw e
  } finally {
    dispatch(updateSendingInvitation(false))
  }
}

export const redeemInvitation = (inviteToken, inviteEmail) => async (_, getState) => {
  const { email } = getState().auth.user

  if (email !== inviteEmail) {
    toast.error(
      'The token you are trying to redeem is for another user, please log out and try again',
      { autoClose: false }
    )
    return browserHistory.push(DASHBOARD_URL)
  }

  try {
    const { body: organization } = await apiClient.post('/organization-invites/redeem', { inviteToken })
    toast.success(`You are now a member of '${organization.name}' organization`)
  } catch (e) {
    toast.error(
      `There was a problem trying to redeem your invitation. Please ask for another invitation. (${e.message})`,
      { autoClose: false }
    )
    browserHistory.push(DASHBOARD_URL)
  }
}

export const removeInvite = (id) => async () => {
  await apiClient.del(`/organization-invites/${id}`)
  toast.success('The invitation was removed successfully')
}

export const loadPendingInvites = () => async (dispatch) => {
  try {
    const { body: invitations } = await apiClient.get('/organization-invites')
    dispatch(updatePendingInvites(invitations.filter(i => !i.accepted)))
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const loadOrganizationMembers = (organizationSlug) => async (dispatch) => {
  dispatch(updateLoadingOrganizations(true))

  try {
    const { body: organizationMembers } =
      await apiClient.get(`/organization-members/${organizationSlug}`)
    dispatch(updateOrganizationMembers(organizationMembers.map(
      ({ id, role, user: { name, email }, createdAt, active }) => ({
        id, name, role, email, createdAt, active
      })
    )))
  } catch (e) {
    Sentry.captureException(e)
  } finally {
    dispatch(updateLoadingOrganizations(false))
  }
}

export const createOrganization = (name, token) => () =>
  apiClient.post('/organizations', { name, token })

export const updateOrganization =
  (orgSlug, { name }) => async (dispatch, getState) => {
    await apiClient.put(`/organizations/${orgSlug}`, { name })

    const { currentOrganization, organizations } = getState().organizations

    const updatedOrganizations = organizations.map(
      o => o.slug === orgSlug ? { ...o, name } : o
    )

    const updatedCurrentOrganization = { ...currentOrganization, name }

    dispatch(updateOrganizations(updatedOrganizations))
    dispatch(updateCurrentOrganization(updatedCurrentOrganization))
  }

export const updateOrganizationMember = ({ memberId, ...values }) => async (dispatch, getState) => {
  dispatch(updateLoadingOrganizations(true))

  try {
    await apiClient.put(`/organization-members/${memberId}`, values)

    const { members } = getState().organizations.currentOrganization
    const memberIndex = members.findIndex(m => m.id === memberId)

    const membersUpdated = members.slice()
    membersUpdated[memberIndex] = { ...members[memberIndex], ...values }

    dispatch(updateOrganizationMembers(membersUpdated))

    toast.success('The member info was updated successfully')
  } catch (e) {
    Sentry.captureException(e)
    toast.error('Something went wrong updating this organization member')
  } finally {
    dispatch(updateLoadingOrganizations(false))
  }
}

export const deleteOrganizationMember = (memberId) => async (dispatch, getState) => {
  dispatch(updateLoadingOrganizations(true))

  try {
    await apiClient.del(`/organization-members/${memberId}`)

    const { members } = getState().organizations.currentOrganization
    const memberIndex = members.findIndex(m => m.id === memberId)

    dispatch(updateOrganizationMembers([
      ...members.slice(0, memberIndex),
      ...members.slice(memberIndex + 1)
    ]))

    toast.success('The organization member has been deleted successfully')
  } catch (e) {
    Sentry.captureException(e)
    toast.error('Something went wrong trying to delete the organization member')
  } finally {
    dispatch(updateLoadingOrganizations(false))
  }
}

export const createUpdateOrganizationTeam = ({ teamId, organization, title }) => async (dispatch, getState) => {
  dispatch(updateLoadingOrganizations(true))

  try {
    const { body: newTeam } = await apiClient.post(teamId ? `/teams/${teamId}` : '/teams', teamId ? { title } : { organization, title })

    const { teams } = getState().organizations.currentOrganization

    if (teamId) {
      const teamIndex = teams.findIndex(m => m.id === teamId)

      const teamsUpdated = teams.slice()
      teamsUpdated[teamIndex] = { ...teams[teamIndex], title }

      dispatch(updateOrganizationTeams(teamsUpdated))
      dispatch(updateWorkspaces(teamsUpdated))
      toast.success('The team info was updated successfully')
    } else {
      // new team has one memberCount when is created (the user that has created the team)
      const teamsUpdated = [...teams, { ...newTeam, $memberCount: 1 }]
      dispatch(updateOrganizationTeams(teamsUpdated))
      dispatch(updateWorkspaces(teamsUpdated))
      toast.success('The team was created successfully')
    }
  } catch (e) {
    Sentry.captureException(e)
    toast.error('Something went wrong.')
  } finally {
    dispatch(updateLoadingOrganizations(false))
  }
}

export const deleteOrganizationTeam = ({ teamId, organization }) => async (dispatch, getState) => {
  dispatch(updateDeletingTeam(true))

  try {
    await apiClient.del(`/teams/${teamId}`)
    const { teams } = getState().organizations.currentOrganization

    console.log('teamId', teamId)
    console.log('teams', teams)

    const teamIndex = teams.findIndex(m => m.id === teamId)

    dispatch(updateOrganizationTeams([
      ...teams.slice(0, teamIndex),
      ...teams.slice(teamIndex + 1)
    ]))
    dispatch(updateWorkspaces(teams.filter(t => t.id !== teamId))
    )
    toast.success('The team was deleted successfully')
  } catch (e) {
    Sentry.captureException(e)
    toast.error('Something went wrong trying to delete the team')
  } finally {
    dispatch(updateDeletingTeam(false))
  }
}

export const leaveOrganization = (memberId) => async (dispatch, getState) => {
  dispatch(updateLoadingOrganizations(true))

  try {
    await apiClient.del(`/organization-members/${memberId}`)

    const { organizations, currentOrganization } = getState().organizations

    dispatch(updateCurrentOrganization({}))

    const updatedOrganizations = organizations.filter(
      o => o.slug !== currentOrganization.slug
    )

    dispatch(updateOrganizations(updatedOrganizations))

    browserHistory.push(DASHBOARD_URL)
    toast.success(`You have left the organization ${currentOrganization.name}`)
  } catch (e) {
    Sentry.captureException(e)
    if (e.message.includes('You cannot delete the last admin of the organization')) {
      toast.error('You cannot leave the organization because you are the last admin. Please assign another admin before leaving.')
    } else {
      toast.error('Something went wrong trying to leave the organization')
    }
  } finally {
    dispatch(updateLoadingOrganizations(false))
  }
}

// actions
export const updateOrganizations = (organizations) => ({
  type: UPDATE_ORGANIZATIONS,
  payload: { organizations }
})

export const updateDeletingTeam = (deletingTeam) => ({
  type: UPDATE_DELETING_TEAM,
  payload: { deletingTeam }
})

export const updateLoadingOrganizations = (loadingOrganizations) => ({
  type: UPDATE_LOADING_ORGANIZATIONS,
  payload: { loadingOrganizations }
})

export const updateCurrentOrganization = (currentOrganization) => ({
  type: UPDATE_CURRENT_ORGANIZATION,
  payload: { currentOrganization }
})

export const updateOrganizationMembers = (members) => ({
  type: UPDATE_ORGANIZATION_MEMBERS,
  payload: { members }
})

export const updateOrganizationTeams = (teams) => ({
  type: UPDATE_ORGANIZATION_TEAMS,
  payload: { teams }
})

export const updateSendingInvitation = (sendingInvitation) => ({
  type: UPDATE_SENDING_INVITATION,
  payload: { sendingInvitation }
})

export const updateUpdatingOrganization = (updatingOrganization) => ({
  type: UPDATE_UPDATING_INVITATION,
  payload: { updatingOrganization }
})

export const updatePendingInvites = (pendingInvites) => ({
  type: UPDATE_PENDING_INVITATIONS,
  payload: { pendingInvites }
})

export const updateInviteLink = (inviteLink) => ({
  type: UPDATE_INVITE_LINK,
  payload: { inviteLink }
})

const initialState = {
  organizations: [],
  teams: [],
  loadingOrganizations: false,
  currentOrganization: {},
  sendingInvitation: false,
  updatingOrganization: false,
  pendingInvites: [],
  inviteLink: null,
  deletingTeam: false
}

// Reducer
export default function reducer (state = initialState, action = {}) {
  const { type, payload } = action
  switch (type) {
    case UPDATE_LOADING_ORGANIZATIONS:
    case UPDATE_ORGANIZATIONS:
    case UPDATE_SENDING_INVITATION:
    case UPDATE_UPDATING_INVITATION:
    case UPDATE_PENDING_INVITATIONS:
    case UPDATE_CURRENT_ORGANIZATION:
    case UPDATE_DELETING_TEAM:
    case UPDATE_INVITE_LINK:
      return { ...state, ...payload }
    case UPDATE_ORGANIZATION_MEMBERS:
      return {
        ...state,
        currentOrganization: {
          ...state.currentOrganization,
          members: payload.members
        }
      }
    case UPDATE_ORGANIZATION_TEAMS: {
      const { currentOrganization, organizations } = state
      const currentOrgIndex = organizations.findIndex(org => org.slug === currentOrganization.slug)

      return {
        ...state,
        organizations: [
          ...organizations.slice(0, currentOrgIndex),
          {
            ...organizations[currentOrgIndex],
            teams: payload.teams
          },
          ...organizations.slice(currentOrgIndex + 1)
        ],
        currentOrganization: {
          ...state.currentOrganization,
          teams: payload.teams
        }
      }
    }
    default:
      return state
  }
}
