import React from 'react'
import { getFormattedDate, normalizeCartridgeName } from 'utils'
import { IconButton } from '@miroculus/nucleo'
import { AiOutlineEdit } from 'react-icons/ai'
import { instrumentRunDetailsUrl } from 'cons/routes'
import PropTypes from 'prop-types'

import classNames from 'classnames/bind'
import styles from './RunsList.scss'

const cx = classNames.bind(styles)

const RunsList = ({ runs = [], orgSlug, deviceId }) => {
  return (
    <table className={cx('table')}>
      <thead>
        <tr className={cx('header')}>
          <th>Protocol</th>
          <th>Version</th>
          <th>Completion</th>
          <th>Recovery</th>
          <th>User</th>
          <th>Cartridge</th>
          <th>Date</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {runs.map(({
          protocolName,
          protocolVersion,
          status,
          recoveryStatus,
          startedAt,
          user,
          electrodeLayout,
          id
        }) => (
          <tr key={id} className={cx('entry')}>
            <td className={cx('protocolName')}>{protocolName}</td>
            <td>{protocolVersion}</td>
            <td>{status}</td>
            <td>{recoveryStatus}</td>
            <td>{user?.name}</td>
            <td>{normalizeCartridgeName(electrodeLayout?.name)}</td>
            <td>{getFormattedDate(startedAt)}</td>
            <td>
              <IconButton
                label='Edit'
                to={instrumentRunDetailsUrl(orgSlug, deviceId, id)}
                data-testid={`edit-run-${id}`}
              >
                <AiOutlineEdit size='1.5em' />
              </IconButton>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

RunsList.propTypes = {
  runs: PropTypes.arrayOf(
    PropTypes.shape({
      protocolName: PropTypes.string,
      protocolVersion: PropTypes.string,
      status: PropTypes.oneOf(['error', 'abort', 'success']),
      recoveryStatus: PropTypes.oneOf(['error', 'success']),
      startedAt: PropTypes.number,
      user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string
      }),
      electrodeLayout: PropTypes.shape({
        name: PropTypes.name
      }),
      id: PropTypes.string
    })
  ),
  orgSlug: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired
}

export default RunsList
